/* eslint-disable @typescript-eslint/ban-types */
import React, {  useState } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Handle, Position } from '@xyflow/react';
import { SidePopover,  } from '../../../globalUtils/globalExports';
import { TriggerPointSidePopover } from '../../componentExports';
import './ifThenElseNode.css'
import ExecutionPlan from './ExecutionPlan';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import './WhenNode.css'

interface CustomNodeProps {
  data: {
    label : string;
    executionPlan: ExecutionPlan;
    handleUpdateExecutionPlan: Function;
    alarmOrParameter:string
  }
}

const WhenNode: React.FC<CustomNodeProps> = ({ data } : CustomNodeProps) => {

  const [isTriggerParameterPopoverOpen, setIsTriggerParameterPopoverOpen] = useState(false);

  const handleEdit =() => {
    setIsTriggerParameterPopoverOpen(true);
  }
  const handleCloseTriggerParameterPopover = () =>{
    setIsTriggerParameterPopoverOpen(false);
  }


  return (
    <>
     <Paper
      elevation={3}
      sx={{
        border: '1px solid #00BFBF',
        borderLeft: '6px solid #00BFBF', 
        borderRadius: '8px',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        minWidth: '300px',
        backgroundColor: '#fff',
        position: 'relative',
      }}
    >
      <Handle type="target" className='transparent-handle' position={Position.Top} style={{ left: '11.8%', transform: 'translateX(-50%)' }} />
      <Handle type="source" className='transparent-handle' position={Position.Bottom} id='bottom' style={{ left: '20%', transform: 'translateX(-50%)' }} />
      <Handle type="source" className='transparent-handle'  position={Position.Left} id='left-A' />

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{display:'flex',marginBottom:'10px',alignItems:'center'}}>

        <AccessTimeOutlinedIcon fontSize="small" sx={{ color: 'gray', marginRight: '4px' }} />      <Box display="flex" alignItems="center" marginRight="8px">
        <Typography
          variant="subtitle2"
          sx={{ fontSize: '1rem', color: '#666', marginRight: '4px' }}
        >
          When
        </Typography>
      </Box>
        </div>

        <div style={{ display:'flex'}}>
      <Box display="flex" alignItems="center" flex="1">
        <Typography
          variant="body2"
          sx={{
            fontSize: '0.875rem',
            fontWeight: 500,
            color: '#333',
          }}
        >
          { data?.executionPlan && data?.executionPlan.trigger && data?.executionPlan.trigger  && data?.executionPlan.trigger.type === 'Parameter' && data?.alarmOrParameter === 'Parameter' &&
                 <div className='rule__engine__if__then__else__node'>
                    <div><span className='rule__engine__define__execution__selected__parameters'>{data?.executionPlan.trigger.name ?? ''}</span></div>
                    &nbsp;<span >{data?.executionPlan.trigger.parameterCondition}</span>&nbsp;
                    <span className='rule__engine__define__execution__selected__parameters'>{data?.executionPlan.trigger.parameterValue[0]}</span>
                    <span className='rule__engine__define__execution__selected__parameters'>{data?.executionPlan.trigger.unit !== null ? data?.executionPlan.trigger.unit  : ''}</span>
                    <span>{data?.executionPlan.trigger.parameterValue.length > 1 &&  data?.executionPlan.trigger.parameterValue[1] }</span>
                    <span className='rule__engine__define__execution__selected__parameters'>{data?.executionPlan.trigger.parameterValue.length > 1 &&  data?.executionPlan.trigger.parameterValue[1] &&  data?.executionPlan.trigger.unit !== null ? data?.executionPlan.trigger.unit + ' ' : ''}</span>
                  </div>
          }
          {
            data?.executionPlan && data?.executionPlan.trigger && data?.executionPlan.trigger  && data?.executionPlan.trigger.type === 'Alarm' && data?.alarmOrParameter === 'Alarm' &&
            <div className='rule__engine__if__then__else__node'>
               <div><span className='rule__engine__define__execution__selected__parameters'>{data?.executionPlan.trigger.name ?? ''}</span></div>
               &nbsp;<span >{data?.executionPlan.trigger.parameterCondition}</span>&nbsp;
               <span className='rule__engine__define__execution__selected__parameters'>{data?.executionPlan.trigger.parameterValue[0]}</span>
             </div>
          }

        </Typography>
      </Box>

      <IconButton
        size="small"
        onClick={handleEdit}
        sx={{
          position: 'absolute',
          right: '8px',
          bottom:'6px'
        }}
      >
        <EditIcon fontSize="small" sx={{ color: '#666' }} />
      </IconButton>
        </div>
    </div>

    </Paper>
    {isTriggerParameterPopoverOpen && <SidePopover
      id='trigger__parameter__when__side__popover'
      open={isTriggerParameterPopoverOpen}
      headerText={'When'}
      containerClassName='configure__notification__detail__popover'
      handleClose={()=>{}}
      popperClassName='trigger__point__side__popover'
      headerClassName={'rule__engine__header__config'}
      horizontalLinePopover={true}
      isRuleEnginePopOver={true}
      ruleEngineSidePopoverOnclose={handleCloseTriggerParameterPopover}
      // eslint-disable-next-line react/no-children-prop
      children={
            <TriggerPointSidePopover 
                handleClose={handleCloseTriggerParameterPopover} 
                executionPlan={data?.executionPlan}
                handleUpdateExecutionPlan={data?.handleUpdateExecutionPlan}
                parameterOrAlarm={data?.alarmOrParameter}
            />
      } 
      />
        }
    </>
   
  );

};

export default WhenNode;
