import React from 'react';
import {Typography } from '@mui/material';


interface CustomNodeProps {
    data: {
        addNodeToCanvas,
        id,
        idToConnect,
        nodes
      };
}

const AddActionNode: React.FC<CustomNodeProps> = (props) => {
    
    return (
        <>
    <Typography
    onClick={() => props.data.addNodeToCanvas(props?.data?.id,props?.data?.idToConnect,props?.data?.nodes)}
    variant="body2"
    sx={{
        color: '#B0B1B6',
        marginTop: '8px',
        marginLeft: '10px',
        cursor: 'pointer',
        fontSize: '0.875rem',
    }}
    >
    + Add Activity
    </Typography>
         
      </>
  );
};

export default AddActionNode;
