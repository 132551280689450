import React, { FC, useState } from 'react';
import { Card, CardContent, Typography, Chip, Box } from '@mui/material';
import { styled } from '@mui/system';

// Mock API response
const mockApiResponse = [
  { heading: 'Draft 1', subheading: 'Reduce charging current recheck after two hours...', status: 'draft' },
  { heading: 'Draft 2', subheading: 'Reduce charging current recheck after three hours...', status: 'draft' },
  { heading: 'Draft 3', subheading: 'Reduce charging current recheck after four hours...', status: 'draft' },
  { heading: 'Draft 3', subheading: 'Reduce charging current recheck after four hours...', status: 'draft' },
  { heading: 'Live Rule 1', subheading: 'Increase current for fast charging by 1 Amp...', status: 'live' },
  { heading: 'Live Rule 2', subheading: 'Increase current for fast charging by 2 Amp...', status: 'live' },
  { heading: 'Live Rule 3', subheading: 'Increase current for fast charging by 3 Amp...', status: 'live' },
  { heading: 'Live Rule 3', subheading: 'Increase current for fast charging by 3 Amp...', status: 'live' },
  { heading: 'Live Rule 3', subheading: 'Increase current for fast charging by 3 Amp...', status: 'live' },
  { heading: 'Live Rule 3', subheading: 'Increase current for fast charging by 3 Amp...', status: 'live' },
  { heading: 'Cancelled Rule 1', subheading: 'Cancelled charging protocol...', status: 'cancelled' },
  { heading: 'Cancelled Rule 2', subheading: 'Cancelled charging protocol...', status: 'cancelled' },
  { heading: 'Cancelled Rule 3', subheading: 'Cancelled charging protocol...', status: 'cancelled' },
];

// Status colors
const statusColors = {
  draft: '#3f51b5', // Blue for draft
  live: '#4caf50',  // Green for live
  cancelled: '#f44336', // Red for cancelled
};

// Styled container for wrapping cards
const CardGridContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap', // Enables flexbox wrapping
  gap: '16px',
  justifyContent: 'left',
//   padding: '16px',
});

// Individual card styling
const StyledCard = styled(Card)(() => ({
  flexBasis: '22%', // Each card takes about 1/4th of the width
  minWidth: '250px',
  borderRadius: 12,
  border: '1px solid #ddd',
  padding: '8px',
}));

// Dashboard component
const RuleEngineDashboard: FC<{ statusType: string, showAllRules: boolean }> = ({ statusType, showAllRules }) => {
  
  const [cards] = useState(!showAllRules && mockApiResponse ? mockApiResponse.filter((item) => item.status === statusType) : mockApiResponse);

  return (
    <Box sx={{ padding: '30px' }}>
      <Typography variant="h6" gutterBottom sx={{ color: 'gray', padding: '8px' }}>
        {statusType.charAt(0).toUpperCase() + statusType.slice(1)}
      </Typography>
      
      {/* Card grid container */}
      <CardGridContainer>
        {cards.map((card, index) => (
          <StyledCard key={index}>
            <Box display="flex" alignItems="center" gap={1}>
              {/* Status Indicator */}
              <Box
                component="span"
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: statusColors[card.status],
                }}
              />
              {/* Heading */}
              <Typography variant="body1" fontWeight="bold">
                {card.heading}
              </Typography>
            </Box>
            {/* Subheading */}
            <CardContent>
              <Typography variant="body2" color="textSecondary">
                {card.subheading}
              </Typography>
            </CardContent>

            {/* Action buttons */}
            <Box display="flex" justifyContent="space-between" padding="8px">
              <Chip
                label="Raise a ticket"
                variant="outlined"
                color="primary"
                size="small"
              />
              <Chip
                label="Remote Command"
                variant="outlined"
                color="secondary"
                size="small"
              />
            </Box>
          </StyledCard>
        ))}
      </CardGridContainer>
    </Box>
  );
};

export default RuleEngineDashboard;