/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { EdgeProps, getSmoothStepPath } from '@xyflow/react';
// import { Box, Typography } from '@mui/material';

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  // data,
  markerEnd,
}: EdgeProps<any>) => {
  // Generate the smooth step path
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 10, // Adjust this to change the smoothness
  });

  // Set label position relative to the target node
  // const offset = 250; 
  // const labelX = targetX - offset; 
  // const labelY = targetY - 15; 

  return (
    <>
      {/* Edge Path */}
      <path
        id={id}
        d={edgePath}
        markerEnd={markerEnd}
        style={{ stroke: '#B0B1B6', strokeWidth: 1, fill: 'none' }} // Adjust strokeWidth for better visibility
      />

      {/* Circular Label Near the Target */}
      {/* <foreignObject
        width={80}
        height={40}
        x={labelX - 40} // Center the label horizontally
        y={labelY - 20} // Center the label vertically
        style={{ overflow: 'visible' }}
      >
        <Box
          sx={{
            width: 'auto',
            height: 'auto',
            backgroundColor: 'white',
            borderRadius: '20%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: 1,
            color: 'black',
            border: '1px solid #ddd',
            padding: '4px 8px', // Add some padding for aesthetics
          }}
        >
          <Typography variant="caption">{data?.label}</Typography>
        </Box>
      </foreignObject> */}
    </>
  );
};

export default CustomEdge;
