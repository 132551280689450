/* eslint-disable @typescript-eslint/ban-types */

import React, {
    FC
    , useCallback, useMemo, useState
} from 'react';
import { useAppDispatch } from '../../../rmsReduxStore/rmsReduxHooks';
import { exportBulkChargerUploadTemplate, fetchPreSignedURLForUpload, uploadBulkChargers } from '../../../rmsReduxStore/chargersRedux/chargerCreators';
import { downloadFile } from '../../../globalUtils/globalHooks';
import axios from 'axios';
import { Id, toast } from 'react-toastify';
import { CustomButtonGroup, } from '../../../globalUtils/globalExports';
import { bulkUploadExample, bulkUploadInfo } from '../chargerConstants';
import { Icon } from '../../../globalUtils/globalIcons';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { FileUploader } from 'react-drag-drop-files';


export interface IPreSignedUrlInfo {
    blobName: string;
    message: string;
    url: string;
}

interface IBulkChargers {
    handlePopOverClose?: Function

}

const AddBulkCharger: FC<IBulkChargers> = (props) => {
    const { handlePopOverClose } = props
    const dispatch = useAppDispatch();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [preSignedUrl, setPreSignedUrl] = useState<IPreSignedUrlInfo | null>(null);
    const [infoVisible, setInfoVisible] = useState(false);
    const [fileName, setFileName] = useState(' Browse Or Drop file');

    const fileDownloadSuccessToast = useCallback((): Id => {
        return toast.success('Template downloaded successfully.');
    }, []);

    const fileDownloadFailureToast = useCallback((): Id => {
        return toast.warn('Something went wrong. Please download again.');
    }, []);

    const bulkChargerCreateSuccessToast = useCallback((): Id => {
        return toast.success('Chargers added.');
    }, [])
    const bulkChargerCreateFailToast = useCallback((): Id => {
        return toast.error('Chargers bulk upload failed.');
    }, [])

    const disableAddButton = useCallback(() => {
        return selectedFile && selectedFile !== null ? false : true;
    }, [selectedFile])

    const handleToggle = (() => {
        if (infoVisible) {
            setInfoVisible(false);
        }
        else setInfoVisible(true);
    })
    const handleDownloadTemplateClick = async (): Promise<void> => {
        const response = await dispatch(exportBulkChargerUploadTemplate());
        if (response?.status === 202 || response?.status === 200) {
            downloadFile({ url: response?.data?.url });
            fileDownloadSuccessToast()
        } else {
            fileDownloadFailureToast();
        }
    };
    const handleFileUpload = async (signedURL: string, file: File): Promise<void> => {
        try {
            const response = await axios.put(signedURL, file, {
                headers: {
                    'Content-Type': file.type,
                    'x-ms-blob-type': 'BlockBlob'
                }
            });
            if (response?.status === 201 || response?.status === 200 || response?.status === 202) {
                const res = await dispatch(uploadBulkChargers({ fileName: preSignedUrl?.blobName }))
                if (res?.status === 201 || res?.status === 200 || res?.status === 202) {
                    bulkChargerCreateSuccessToast();
                    setTimeout(() => {
                        setSelectedFile(null);
                        handlePopOverClose?.(true);
                    }, 2000)
                }
                else {
                    toast.error(res?.data?.message);
                }

            }
            else {
                bulkChargerCreateFailToast()
                setTimeout(() => {
                    setSelectedFile(null);
                    handlePopOverClose?.(false);
                }, 2000)
                throw new Error('Failed to upload file');
            }
        } catch (error) {
            setSelectedFile(null);
            handlePopOverClose?.(false);
            console.error('Error uploading file:', error);
        }
    };

    const btnsList = useMemo(() => {
        return [
            {
                buttonText: 'Upload',
                buttonId: 'add',
                btnClassName: disableAddButton() ? 'primary__btn disabled' : 'primary__btn',
                handleClick: (): void => {
                    if (preSignedUrl && selectedFile) {
                        handleFileUpload(preSignedUrl?.url, selectedFile);
                    }
                    else {
                        setSelectedFile(null);
                        handlePopOverClose?.(false);
                    }
                },
                isDisabled: disableAddButton(),
                buttonVariant: 'filled',
            },
            {
                buttonText: 'Cancel',
                buttonId: 'cancel',
                btnClassName: 'secondary__btn',
                handleClick: (): void => {
                    setSelectedFile(null);
                    handlePopOverClose?.(false)
                },
                isDisabled: false,
                buttonVariant: 'outlined',
            },
        ];
    }, [disableAddButton, preSignedUrl, selectedFile]);


    const handleFileChange = async (file: File | null): Promise<void> => {

        if (file && file?.name) {
            setSelectedFile(file);
            const response = await dispatch(fetchPreSignedURLForUpload({ fileName: file?.name }));
            setPreSignedUrl(response?.data);
        } else {
            setSelectedFile(null);
        }
    };

    const fileTypes = ['CSV', 'XLSX'];
    const handleFileSelect = (file: File | null) => {
        if (file && file?.name)
            setFileName(file?.name)
    }

    return (
        <>
            <div className='bulk-chargers-container'>
                <div style={{ overflowY: 'auto' }}>
                    <div className='charger_download_template'>
                        <Timeline
                            className='timeline_container'
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                            }}>
                            <TimelineItem>
                                <TimelineSeparator><TimelineDot className='timeLine__dot' /><TimelineConnector className='timeLine__connector' /></TimelineSeparator>
                                <TimelineContent><div onClick={handleDownloadTemplateClick} style={{ color: '#06bfbf', cursor: 'pointer', display: 'flex' }}>
                                    Download the Template
                                </div>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot className='timeLine__dot' />
                                    <TimelineConnector className='timeLine__connector' />
                                </TimelineSeparator>
                                <TimelineContent>Fill the Charger Details</TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot className='timeLine__dot' />
                                </TimelineSeparator>
                                <TimelineContent>Select and Upload the Filled template</TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </div>
                    <div>
                        <div className='charger_info_drop_down' onClick={handleToggle} >
                            <Icon iconClassName={infoVisible ? 'grid__layout__expandable__up-icon' : 'grid__layout__expandable__down-icon'} />
                            <h6 className='charger-info-heading' >Instructions for using this template</h6>
                        </div>
                        {infoVisible && bulkUploadInfo && bulkUploadInfo?.map((section, index) => (
                            <>
                                <div key={index}>
                                    <h6 style={{ fontSize: '14px', marginTop: '10px', color: 'grey', fontWeight: '500' }}>{section.header}</h6>
                                    <ul style={{ fontSize: '12px', margin: '16px', color: 'grey', }}>
                                        {section.content.map((item, i) => (
                                            <li key={i} style={{ marginTop: '10px', }}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        ))}
                    </div>
                    <div style={{ border: '2px solid rgb(6, 191, 191)', borderRadius: '12px', marginTop: '10px' }}>
                        <div className='charger_example_template'>
                            {bulkUploadExample && bulkUploadExample?.map((section, index) => (
                                <div key={index}>
                                    <h6 style={{ fontSize: '14px', marginTop: '10px', color: '#06bfbf', fontWeight: '500' }}>{section.header}</h6>
                                    <ul style={{ fontSize: '12px', margin: '15px' }}>
                                        {section.content.map((item, i) => (
                                            <li key={i} style={{ marginTop: '10px', }}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>


                    <div className='bulk-charger-upload' style={{ paddingTop: '30px' }}>

                        {/* <CustomUpload
                        fileName={selectedFile ? selectedFile.name : ''}
                        inputFieldId="adding_station"
                        isRequired={true}
                        handleInputChange={handleFileChange}
                        className={'bulk_charger_upload'}
                    /> */}
                        <FileUploader
                            className='charger__bulk__upload__drag__file'
                            multiple={false}
                            handleChange={handleFileChange}
                            name={selectedFile ? selectedFile?.name : ''}
                            types={fileTypes}
                            required={true}
                            label={fileName}
                            onSelect={handleFileSelect}

                        />
                        <p style={{ fontSize: '14px', fontFamily: 'sans-serif', color: 'grey', padding: '6px 4px' }}>{selectedFile && selectedFile ? selectedFile?.name : 'No file uploaded!'}</p>
                    </div>
                </div>
                <div className='bulk_charger-upload-buttons'>
                    <CustomButtonGroup
                        buttonsList={btnsList}
                        buttonGroupClassName='button__group__footer' />
                </div>
            </div>


        </>
    )

}

export default AddBulkCharger;