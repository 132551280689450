/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React, { memo, } from 'react';
import { TableLayout, } from '../../globalUtils/globalExports';
import './HistoryPage.css'

export interface HistoryPageProps {
  tableRows?: Array<any> | null;
  totalCount: number;
  layoutView?: string;
  layoutsEnabled?: boolean;
  disablePaginationFooter?: boolean;
  listLayoutCallBack?: Function;
  gridColumns?: Array<any> | null;
  showLoader?: boolean,
  showSkeleton?: boolean;
  pageNumber?: number;
  tablePageSize?: number;
  sortField?: string;
  sortOrder?: string;
  leftPinnedColumns?: string[]
  columnPrefrences?: ITableColumnPrefrences
  handleColumnPrefrenceChange?: Function;
  totalCountText?: string;

}

const HistoryPage = memo(function HistoryPage({
  tableRows,
  totalCount,
  listLayoutCallBack,
  gridColumns,
  showLoader,
  showSkeleton,
  pageNumber,
  tablePageSize,
  sortField,
  sortOrder,
  columnPrefrences,
  handleColumnPrefrenceChange,
  totalCountText,
  leftPinnedColumns,

}: HistoryPageProps) {

  return (
    <>
      <TableLayout
        headerText='History'
        tableClassName='history_page'
        totalCount={totalCount}
        tableRows={tableRows}
        layoutView='list'
        gridColumns={gridColumns}
        listLayoutCallBack={listLayoutCallBack}
        showLoader={showLoader}
        showSkeleton={showSkeleton}
        pageNumber={pageNumber}
        tablePageSize={tablePageSize}
        sortField={sortField}
        sortOrder={sortOrder}
        columnPrefrences={columnPrefrences}
        handleColumnPrefrenceChange={handleColumnPrefrenceChange}
        totalCountText={totalCountText}
        leftPinnedColumns={leftPinnedColumns}
      />
    </>
  );
})

export default HistoryPage