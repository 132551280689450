/* eslint-disable @typescript-eslint/ban-types */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomButtonGroup, CustomInput, SelectDropDown, useAppDispatch, useAppSelector, } from '../../../globalUtils/globalExports';
import { fetchRuleEngineSetParameterParams, getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import './IfThenElseSidePopOver.css'
import '../../../rmsAssets/rmsIcons.css'
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Icon } from '../../../globalUtils/globalIcons';
import { parseDropDownValues } from '../../chargers/ChargerPanel/ChargerPanelSettingsDashboard';
import './IfThenElseSidePopOver.css'
import { cloneDeep, isEqual } from 'lodash';
import ExecutionPlan, { SetParameterAction, SetParameters } from './ExecutionPlan';
import { checkValidDecimal, checkValidInteger } from './itThenElseSidePopOver';

interface IfThenElseSidePopOverProps {
    setParameters: null | SetParameters[];
    handleClose: () => void;
    executionPlan: ExecutionPlan;
    handleUpdateExecutionPlan: Function;
    ifOrElse: String;
}

const SetParameterSidePopOver = memo(function IfThenElseSidePopOver({
    setParameters,handleClose,executionPlan,handleUpdateExecutionPlan,ifOrElse
} : IfThenElseSidePopOverProps){    

    const dispatch = useAppDispatch(); 
    const { ruleEngineSetParameterParam,} = useAppSelector(getReferenceDataReducer);
    const [popOverData,setPopOverData] = useState<SetParameters[]>([{
        parameterId: '',
        parameterName: '',
        parameterCondition: '',
        parameterConditionName: '',
        parameterValue: [],
        parameterOperator: '',
        fieldType: '',
        unit: '',
        possibleValue: '',
        errorMessage: '',
        parameterType: null
    }])
    const [initialPopOverData,setInitialPopOverData] = useState<SetParameters[]>([{
        parameterId: '',
        parameterName: '',
        parameterCondition: '',
        parameterConditionName: '',
        parameterValue: [],
        parameterOperator: '',
        fieldType: '',
        unit: '',
        possibleValue: '',
        errorMessage: '',
        parameterType: null
    }])

    useEffect(()=>{
        if(setParameters){
            setInitialPopOverData(setParameters)
            setPopOverData(setParameters);
        }
    },[])
   

    useEffect(()=>{
        dispatch(fetchRuleEngineSetParameterParams());
    },[])



    const checkValidationsAndShowError = (subParameter, value): string | null => {
        
        const parsedValues = JSON.parse(subParameter?.possibleValue)
        if (subParameter?.dataType === 'Integer') {
          return value && !checkValidInteger(value) ? 'Enter a valid Integer value' :  subParameter?.parameterConditionName === 'Between' ? ((subParameter && subParameter?.parameterValue.length > 1 &&  subParameter?.parameterValue[0] >  subParameter?.parameterValue[1]) ? 'The second value must be greater than the first value' : null) : parsedValues && !(parseInt(value) >= parsedValues['min'] && parseInt(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.`
           : null
        } else if (subParameter?.dataType === 'Double') {
          return value && !checkValidDecimal(value) ? 'Enter a valid Double value' : subParameter?.parameterConditionName === 'Between' ? ((subParameter && subParameter?.parameterValue.length > 1 &&  subParameter?.parameterValue[0] >  subParameter?.parameterValue[1]) ? 'The second value must be greater than the first value' : null) : parsedValues && !(parseFloat(value) >= parsedValues['min'] && parseFloat(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.` : null
        } 
        return null
      }
    
  

    const disableButton = useCallback(() => {
            if(isEqual(initialPopOverData,popOverData)){
                return true
            }
            else{
                let result = true;
             popOverData.forEach((item,) => {
                if(item?.parameterId.length > 0 && item.parameterCondition.length >0 && item.parameterValue.length > 0){
                    result = result && item.parameterId.length > 0  && item.parameterCondition.length > 0 && item.parameterValue && item.parameterValue.length > 0 && item.parameterValue[0].length > 0
                    if(item?.errorMessage && item?.errorMessage.length > 0)
                        result = result && !(item?.errorMessage.length > 0)
                    } else {
                        result = false;
                    }
                });
            return !result; 
            }
    }, [popOverData]);

    const checkMinMax =(val) =>{
        if (!val || typeof val !== 'string') {
            return false;
        }
        try {
            const options = parseDropDownValues(val);
            return (options.length >0 && (options[0].id === 'min' || options[1].id === 'max')) ? true : false;
        } catch (error) {
            return false;
        }
        return false;
    }

    const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Save',
            buttonId: 'add',
            btnClassName:  disableButton() ? 'primary__btn disabled' : 'primary__btn',
            handleClick: (): void => {
                
                const data = cloneDeep(popOverData);
                const formattedData : SetParameterAction = {
                    type: 'Set Parameter',
                    parameters: data, 
                }
                const executionData = cloneDeep(executionPlan);
                
                const setParameterIndex = executionData?.action?.find((executionDataItem) => executionDataItem.flowType === ifOrElse)?.list?.findIndex((executionPlanAction) => executionPlanAction.type === 'Set Parameter')

                if (setParameterIndex !== undefined && setParameterIndex !== -1) {
                  executionData.action[ifOrElse === 'If' ? 0 : 1 ].list[setParameterIndex] = formattedData
                } else {
                    executionData.action[ifOrElse === 'If' ? 0 : 1].list?.push(formattedData)
                }

                handleUpdateExecutionPlan(executionData);
                handleClose();

            },
            isDisabled: disableButton(),
            buttonVariant: 'filled',
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: (): void => {
                handleClose()
            },
            isDisabled: false,
            buttonVariant: 'outlined',
          },
        ];
      }, [popOverData]);

    const handleClick = () => {
        setPopOverData([...popOverData, {
            parameterCondition: '', parameterOperator: 'AND', parameterValue: [], fieldType: '', unit: '', possibleValue: '',
            parameterId: '',
            errorMessage: '',
            parameterName: '',
            parameterConditionName: '',
            parameterType: null
        }])
    };

    const handleDeleteIconClick = (i: number)=>{
        const data = popOverData.filter((item, index)=>{
            return (index!==i)
        })
        setPopOverData(data);

    }

    const renderDropDownOptions = (val) => {
        if (!val || typeof val !== 'string') {
            return [];
        }
        try {
            const options = parseDropDownValues(val);
            return options;
        } catch (error) {
            return [];
        }
    };
       

    // const handleErrorMessage = (val : string)=>{
    //     console.log(val);
    // }
    return(
        <>
        <div className='flow__controls__side__popover__wrapper'>
            <div className='flow__control__side__popover__column__label'>
                <div className='flow__control__side__popover__label flow__control__side__popover__parameter__label'>
                    Parameter*
                </div>
                <div className=' flow__control__side__popover__label flow__control__side__popover__operator__label'>Operator*
                </div>
                <div className='flow__control__side__popover__label flow__control__side__popover__value__label'>
                Value*
                </div>
            </div>
            <hr className="custom-hr" />
            <div className='flow__control__side__popover__parameters'>
            {
                popOverData && popOverData?.map((item, i)=>{
                return <div key = {i}>
                    {item.parameterOperator && 
                    <div className='flow__control__side__popover__logical__operator'>
                        {/* <SelectDropDown 
                          selectDropDownId={'flow__control__side__popover__logical__operator'} 
                          handleSelectOptionChange={(val)=>{
                            const data = cloneDeep(popOverData);
                            data[i]['parameterLogicalOperator'] = val.name;
                            setPopOverData(data);
                          }}
                          selectOptions={ruleEngineParamOperatorReferenceData ?? []}
                          selectValue={{id : item.parameterLogicalOperator, label : item.parameterLogicalOperator}}
                          /> */}
                          <CustomInput 
                                inputValue={'AND'} 
                                inputFieldId={'rule__engine__set__parameter__logical__operator'} 
                                isRequired={true}   
                                isDisabled={true}
                                // handleInputChange={(e) => {
                                // const data = cloneDeep(popOverData);
                                // data[i]['parameterValue'] = e.target.value;
                                // const error = checkValidationsAndShowError(popOverData[i],e.target.value);
                                // data[i]['errorMessage'] = error!==null ? error : '';
                                // setPopOverData(data);
                                // handleErrorMessage(data[i]['parameterValue'][0])
                                // }} 
                                // errorMessage={popOverData[i]['errorMessage']}
                            />
                        </div>}
                    <div className='flow__control__side__popover__dropdown'>

                        <div className='flow__control__side__popover__parameter__label'>
                        <SelectDropDown 
                        dropDownClassName={'parameter__select'}
                        selectDropDownId={'paramter__name'}
                        selectValue={{id: item.parameterName,label: item.parameterName}}
                        renderToBody={true}
                        handleSelectOptionChange={(val) : void =>{
                        // if(val?.dataType){
                        //     setSelectedParameter(val);
                        // }
                        const data = cloneDeep(popOverData);
                        data[i]['parameterId'] = val?.id;
                        data[i]['parameterName'] = val?.label;
                        data[i]['parameterCondition'] = (val?.possibleValue && val?.possibleValue!== null && !checkMinMax(val?.possibleValue) ) ? 'is': 'equalsTo' ;
                        data[i]['parameterConditionName'] = (val?.possibleValue && val?.possibleValue!== null && !checkMinMax(val?.possibleValue) ) ? 'is': 'Equals to' ;
                        data[i]['parameterValue'] = [];
                        data[i]['fieldType'] = val?.fieldType;
                        data[i]['dataType'] =  val?.dataType;
                        data[i]['unit'] = val?.unit;
                        data[i]['possibleValue'] = val?.possibleValue;
                        data[i]['errorMessage'] = '';
                        data[i]['parameterType'] = val?.parameterType;
                        setPopOverData(data);
                        }} 
                        selectOptions={ruleEngineSetParameterParam ?? []}        
                        />
                        </div>
                        <div className='flow__control__side__popover__operator__label'>
                        {/* <SelectDropDown 
                        selectDropDownId={'paramter__operator__value'} 
                        handleSelectOptionChange={(val)=>{
                            const data = [...popOverData];
                            data[i]['parameterOperator'] = val?.name;
                            setPopOverData(data);
                        }} 
                        renderToBody={true}
                        selectOptions={operator === 'String' ? ruleEngineParamConditionReferenceData?.slice(0,2) ?? [] : ruleEngineParamConditionReferenceData?.slice(2) ?? []}   
                        selectValue={{id: item.parameterOperator,label: item.parameterOperator}}          
                        /> */}
                        <CustomInput
                         inputValue={item.parameterConditionName??''}
                          inputFieldId={'rule__engine__set__parameter__operator__value'} 
                          isRequired={true}
                          isDisabled={true}
                          />
                        </div>

                        <div className='flow__control__side__popover__value__label'>
                        {item?.fieldType === 'DropDownMultiple' ? (
                            <div style={{width: '180px'}}>
                                 <SelectDropDown 
                                    selectDropDownId={'rule__engine__set__parameter__dropdown__value'} 
                                    handleSelectOptionChange={(val)=>{
                                        const data = cloneDeep(popOverData);
                                        data[i]['parameterValue'].push(val?.label);
                                        setPopOverData(data);
                                    } } 
                                    selectOptions={renderDropDownOptions(popOverData[i]?.possibleValue) ?? []} 
                                    selectValue={{id : item.parameterValue, label : item.parameterValue}}
                                    isDisabled={popOverData[i]['parameterCondition'].length > 0 ? false : true}
                                    isMultiSelect={true}
                            />
                            </div>
                        ) : item?.fieldType === 'DropDownSingle' ?
                             <div style={{width: '180px'}}>
                                <SelectDropDown 
                                    selectDropDownId={`rule__engine__set__parameter__drop__down__value__${i}`}
                                    selectOptions={renderDropDownOptions(popOverData[i]?.possibleValue) ?? []} 
                                    isDisabled={popOverData[i]['parameterCondition'].length > 0 ? false : true}
                                    handleSelectOptionChange={(val)=>{
                                        const data = cloneDeep(popOverData);
                                        data[i]['parameterValue'][0] = val?.label
                                        setPopOverData(data);
                                    }} 
                                    isMultiSelect={false}
                                    selectValue={{ id: item.parameterValue[0], label : item.parameterValue[0]}}
                             />
                             </div>
                          : 
                         (
                            <>
                            <CustomInput 
                                inputValue={item.parameterValue.length > 0 ? item.parameterValue[0] : ''} 
                                inputFieldId={'rule__engine__set__parameter__parameter__value'} 
                                isRequired={true}   
                                isDisabled={popOverData[i]['parameterCondition'].length>0 ? false : true}
                                handleInputChange={(e) => {
                                const data = cloneDeep(popOverData);
                                data[i]['parameterValue'][0]= (e.target.value);
                                const error = checkValidationsAndShowError(popOverData[i],e.target.value);
                                data[i]['errorMessage'] = error!==null ? error : '';
                                setPopOverData(data);
                                }} 
                                errorMessage={popOverData[i]['errorMessage']}
                            />
                            {item?.fieldType === 'Input' && item?.unit && item?.unit !== 'NA' && (
                                <div className='charger__status__toggle__wrap__content-row-value-wrap-unit'>
                                {item.unit.slice(0,3)}
                                </div>
                            )}
                            
                            </>
                        )}
                        </div>
                       {
                        i>0 && <Icon iconClassName='rms__delete__icon' onIconClick={() => handleDeleteIconClick(i)}/>

                       }

                        </div>
                    </div>
                })
            }
            </div>
            <Button
                startIcon={<AddIcon />}
                sx={{ color: '#49c8d1', textTransform: 'none', fontSize: '1rem',marginTop:'12px' ,display: 'flex', justifyContent: 'flex-start', width:'180px'}}
                onClick={handleClick}
            >
                <div className='flow__control__side__popover__add__condition'>  Add Condition </div>
            </Button>  

        </div>
        <div className='rule__engine__if__then__else__side__popover__footer'>
          <CustomButtonGroup
            buttonsList={btnsList}
            buttonGroupClassName='button__group__footer' />
        </div>
        </>
    )
})

export default SetParameterSidePopOver;