/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { IconWithTooltip } from '../globalIcons';
import { ruleEngineStatusMapper } from '../../components/ruleEngine/ruleEngineConstant';
import { alarmCardMapper, CustomSwitch, } from '../globalExports';


export interface IGridCard {
  id: string,
  name: string,
  runningStatus: string
  status: string,
  description: string,
  action: string[] | null,
  notifyUsingSms: string,
  notifyUsingEmail: string,
  lastTriggerTime: string,
  alarmSeverity: string,
  visibleAlarmName: string,
}

export interface IGridSingleCard {
  cardClassName?: string,
  cardData: IGridCard,
  screen?: string,
  showDescription?: boolean,
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleToggleChange: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleDelete?: Function,
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleSingleCardClick?: Function
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleSingleCardClickRuleEngine?: Function
  
}
import './Cards.css'
import { formatDateInDateTimeFormat } from '../globalHooks';

const GridSingleCard: FC<IGridSingleCard> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { cardData, screen, handleToggleChange, handleDelete, cardClassName, handleSingleCardClick,handleSingleCardClickRuleEngine } = props
  const [localStatus, setLocalStatus] = useState(cardData?.status === 'Enabled');

  const handleCardClick = (e, cardData,id: string) => {
    if (screen === 'configureNotificaion' ) {
      handleSingleCardClick?.(e, id);
    }
    if (screen === 'ruleEngine') {
      handleSingleCardClickRuleEngine?.(e,cardData)
    }
      
  }

  return (
    <>
      <div className={'rule_engine_card__container ' + cardClassName} onClick={(e) => { handleCardClick(e,cardData, cardData?.id); }}   style={{ cursor: 'pointer' }}  >
        <div className='rule_engine_card_header'>
          <IconWithTooltip iconClassName={ruleEngineStatusMapper[cardData?.runningStatus]?.icon} />
          <div className='rule_engine_card_rule_name_container'>
            <div className='rule_engine_card_rule_name'>
              {cardData?.name}
            </div>
          </div>
          <div className='rule_engine_card_status_delete_container'>
            <div onClick={(event) => { event.stopPropagation() }} >
              <CustomSwitch
                switchId={`rule__engine__${cardData?.id}__status-switch`}
                switchClassName="rule__engine__status-switch"
                checked={localStatus}
                handleClick={(e) => { 
                  if(handleToggleChange(cardData?.id)){
                  setLocalStatus(!localStatus)
                }
               }}
                isDisabled={false}
              />
            </div>
            <div className='rule_engine_card_delete_icon'>
              {<IconWithTooltip iconClassName={'rms__delete__icon'} onIconClick={(event) => { event.stopPropagation(); handleDelete?.(cardData?.id) }} />}
            </div>
          </div>
        </div>
        {screen && screen === 'ruleEngine' && 
        <div className='rule_engine_card_body'>
          {cardData?.description ?? '-'}
        </div>}

        {screen && screen === 'configureNotificaion' && <div className='rule_engine_card_date_container'>
          {cardData?.lastTriggerTime  ? formatDateInDateTimeFormat(cardData?.lastTriggerTime) : '-'}
        </div>}

        {screen && screen === 'configureNotificaion' && <div className='rule_engine_card_alarm_type_container'>
          <IconWithTooltip iconClassName={alarmCardMapper[cardData?.alarmSeverity?.toUpperCase()]?.alarmSummaryIcon} toolTipText={alarmCardMapper[cardData?.alarmSeverity?.toUpperCase()]?.title} />
          <div> {cardData?.visibleAlarmName}</div>
        </div>}

        {screen && screen === 'ruleEngine' && cardData?.action !=null && Array.isArray(cardData?.action) && 
          <div className='rule_engine_card_footer_display_action_buttons'>
            {cardData?.action?.filter((item) => item)?.slice(0, 2).map((action, key) => (
              <div key={key} className="rule_engine_card_actionButton">
                {action}
              </div>
            ))}
            {cardData?.action?.length && cardData?.action?.length > 2 && (
              <div className="rule_engine_card_actionButton">
                +{cardData.action.length - 2}
              </div>
            )}
          </div>}

        {cardData?.notifyUsingSms === 'Yes' &&
          <div className='rule_engine_card_footer_display_notification'>
            <IconWithTooltip iconClassName='configure_notification_sms_email_icon' />
            {<div className="rule_engine_card_sms_notification">
              SMS
            </div>}
            {cardData?.notifyUsingEmail === 'Yes' && <div className="rule_engine_card_sms_notification">
              | Email
            </div>}
          </div>}

        {cardData?.notifyUsingSms === 'No' &&
          <div className='rule_engine_card_footer_display_notification'>
            <IconWithTooltip iconClassName='configure_notification_sms_email_icon' />
            {cardData?.notifyUsingEmail &&
              <div className="rule_engine_card_sms_notification">
                Email
              </div>}
          </div>}
      </div>
    </>

  );
};
export default GridSingleCard;