import access from '../../auth/service/AccessControl';

export const configureNotificationDashboardNavList = [
  {
    id: 'configureNotification',
    text: 'Notification Rules',
    isAccessible: access?.configureNotifications?.singleConfigureNotification?.summary?.view()
  },
  {
    id: 'configureNotificationHistory',
    text: 'History',
    isAccessible: access?.configureNotifications?.singleConfigureNotification?.History?.view()
  },
]

export const configureNotificationPopoverNavList = [
  {
    id: 'summary',
    text: 'Summary',
    isAccessible: access?.configureNotifications?.singleConfigureNotification?.summary?.view()
  },
  {
    id: 'history',
    text: 'History',
    isAccessible: access?.configureNotifications?.singleConfigureNotification?.History?.view()
  },
]

