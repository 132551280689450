import React, { useEffect, useRef, useState } from 'react';
import { Menu, MenuItem, Button, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppSelector } from '../globalExports';
import { getRuleEngineReducer } from '../../rmsReduxStore/reduxExports';

const StyledMenu = styled(Menu)({
  '& .MuiMenu-paper': {
    boxShadow: 'none',
  },
});

const StyledMenuItem = styled(MenuItem)({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent !important',
  },
  color: 'black',
});
/* eslint-disable react/prop-types */
const RowsPerPageMenu = ({ onCountChange, }) => {
const {ruleEngineListTableProps } = useAppSelector(getRuleEngineReducer)
    const ruleEngineListTablePropsRef = useRef(ruleEngineListTableProps);
    
        
    useEffect(() => {
        ruleEngineListTablePropsRef.current = ruleEngineListTableProps;
    }, [ruleEngineListTableProps]);
    
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ruleEngineListTablePropsRef?.current?.pageSize ?? 15);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
      setAnchorEl(event.currentTarget);
      
  };

  const handleClose = (value?: number) => {
    if (value !== undefined) {
        setRowsPerPage(value); 

      onCountChange(value)
    }
      setAnchorEl(null);
  };

  return (
    <Box display='flex' alignItems='center'>
      <Typography variant='body2' style={{ color: 'black', marginRight: '5px' }}>
        Cards per page:
      </Typography>
      <Button
        aria-controls={open ? 'rows-per-page-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='text'
        style={{
          color: 'black',
          fontSize: '14px',
          fontWeight: 400,
          padding: 0,
          textTransform: 'none',
        }}
        endIcon={
          <KeyboardArrowDownIcon
            style={{ fontSize: '18px', color: 'black' }} // Smaller dropdown icon
          />
        }
      >
        {rowsPerPage}
      </Button>
      <StyledMenu
        id='rows-per-page-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {[10, 15, 25, 50, 100].map((value) => (
          <StyledMenuItem
            key={value}
            selected={value === rowsPerPage}
            onClick={() => handleClose(value)}
          >
            {value}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
};

export default RowsPerPageMenu;