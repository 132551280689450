import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LinkButton, { LinkButtonPropTypes } from '../LinkButton/LinkButton';
import './CustomBreadCrumbs.css'
import { Divider, IconButton, Paper, Popper } from '@mui/material';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconWithTooltip } from '../globalIcons';

export interface BreadCrumbObject {
  objecType: 'link' | 'text'
  linkBtnProps?: LinkButtonPropTypes
  textProps?: string
}
export interface CustomBreadCrumbsProps {
  containerId?: string
  containerClassName?: string
  breadCrumbs: BreadCrumbObject[]
  customRuleEngine?: boolean
  levelOfHeirarchy?: string
}

const CustomBreadCrumbs: React.FC<CustomBreadCrumbsProps> = (props) => {
  const { containerId, containerClassName, breadCrumbs, customRuleEngine,levelOfHeirarchy } = props
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };


  return (
    <>
   
    <Stack key={containerId} className={'custom__breadCrumb__wrap ' + containerClassName} spacing={1}>
      {!customRuleEngine ?
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadCrumbs && breadCrumbs?.length > 0 && breadCrumbs?.map((item, index) => {
            return (
              <div key={index} className={'custom__breadCrumb__wrap-content-column'} >
                {item?.objecType === 'link' && item?.linkBtnProps &&
                  <>
                    <LinkButton {...item?.linkBtnProps} />
                  </>
                }
                {item?.objecType === 'text' && item?.textProps &&
                  <Typography variant="h6" style={{ fontSize: '14px', fontFamily: 'var(--font-family)', lineHeight: 0 }}>
                    {item?.textProps}
                  </Typography>
                }
              </div>
            )
          })}
        </Breadcrumbs>
        :
        <>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        
      <Breadcrumbs separator={<></>} aria-label="breadcrumb">
  <div>
    
  </div>
{breadCrumbs && breadCrumbs.length > 0 && breadCrumbs.map((item, index) => {
return (

<div
  key={index}
  className="custom__breadCrumb__wrap-content-column-custom"
  style={{ 
    display: 'flex',
    alignItems: 'center',
    margin: index === 2 ? '20px 0 0 20px' : '20px 0 0 30px' // Adjust margin for the third item
  }}
>
  {/* Add the grey bullet dot for the third item */}
  {index === 2 && (
    <span 
      style={{
        width: '6px',
        height: '6px',
        backgroundColor: 'grey',
        borderRadius: '50%',
        marginRight: '8px',
        marginLeft:'-20px'
      }}
    />
  )}

  {/* Render the link or text */}
  {item?.objecType === 'link' && item?.linkBtnProps && (
    <LinkButton {...item?.linkBtnProps} />
  )}
  {item?.objecType === 'text' && item?.textProps && (
    <Typography
      variant="h6"
      style={{
        fontSize: index === 2 ? '18px' : '24px', // Smaller font size for the third item
        color: 'black',
        fontFamily: 'var(--font-family)',
        lineHeight: 1, // Adjust line height for alignment
      }}
    >
      {item?.textProps}
    </Typography>
  )}
</div>     
);
})}
      </Breadcrumbs>
      <div style={{ marginRight: '30px' }}>
      {/* Icon Button */}
      <IconButton
        ref={anchorRef}
        onClick={handleClick}
       style={{marginBottom:'-25px'}}
      >
       <IconWithTooltip iconClassName={open ? 'execution_plan_summary_icon_blue' : 'execution_plan_summary_icon_black'} />
      </IconButton>

      {/* Popper */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
                  style={{
                    zIndex: 1300,
                    boxShadow: 'none'
                  }}
                  
      >
      <Paper
      elevation={3}
      style={{
        padding: '16px',
        maxWidth: '300px',
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginTop: '30px',
      }}
>
  {/* Header */}
  <Typography
    variant="subtitle2"
    style={{  marginBottom: '16px', fontSize: '16px', color: 'gray' }}
  >
    SUGGESTIONS
  </Typography>

 {/* Suggestion Items */}         
  { levelOfHeirarchy == 'action' && [
    { title: 'Set Parameter', description: 'Actions will execute when your rule runs successfully.',className:'set-parameter-sidebar-gray'},
    { title: 'Remote Command', description: 'Actions will execute when your rule runs successfully.',className:'remote-command-sidebar-gray'},
    { title: 'Raise a Ticket', description: 'Actions will execute when your rule runs successfully.',className:'raise-a-ticket-sidebar-gray'},
    { title: 'Send Notification', description: 'Actions will execute when your rule runs successfully.',className:'send-notification-sidebar-gray' },
  ].map((item, index) => (
    <div key={index} style={{  marginBottom: '16px', display:'flex', }}>
        <div style={{ margin:'auto' ,marginRight: '12px', color: 'gray' }}>
          <IconWithTooltip iconClassName={item?.className} />
        </div>
      <div style={{ display: 'flex', flexDirection:'column', alignItems: 'left', marginBottom: '4px' }}>
        <Typography variant="body2" style={{ fontSize: '14px', color: 'gray' }}>
          {item.title}
        </Typography>
      <Typography variant="body2" style={{ fontSize: '12px', color: 'gray' }}>
        {item.description}
      </Typography>
      </div>
    </div>
  ))}
  { levelOfHeirarchy == 'flow' && [
    { title: 'Then', description: 'Actions will execute, if the trigger condition is true.',className:'flowcontrol-gray'},
    { title: 'If - Then', description: 'Checks additional ‘If’ conditions; Actions will execute, if the conditions are true.',className:'flowcontrol-gray'},
    { title: 'If - Then - Else', description: 'Checks additional ‘If’ condition; Alternate actions will execute, if the conditions are true/false.',className:'flowcontrol-gray'},
  ].map((item, index) => (
    <div key={index} style={{  marginBottom: '16px', display:'flex', }}>
        <div style={{ margin:'auto' ,marginRight: '12px', color: 'gray' }}>
          <IconWithTooltip iconClassName={item?.className} />
        </div>
      <div style={{ display: 'flex', flexDirection:'column', alignItems: 'left', marginBottom: '4px' }}>
        <Typography variant="body2" style={{ fontSize: '14px', color: 'gray' }}>
          {item.title}
        </Typography>
      <Typography variant="body2" style={{ fontSize: '12px', color: 'gray' }}>
        {item.description}
      </Typography>
      </div>
    </div>
  ))}
  { levelOfHeirarchy == 'trigger' && [
    { title: 'Parameters', description: 'The rule executes when the specified parameter condition is met.',className:'parameters-gray'},
    { title: 'Alarms', description: 'The rule executes when the defined alarm is triggered.',className:'alarms-gray'},
    { title: 'Schedule Time', description: 'The rule executes at the predefined schedule.',className:'scheduleTime-sidebar-gray'},
  ].map((item, index) => (
    <div key={index} style={{  marginBottom: '16px', display:'flex', }}>
        <div style={{ margin:'auto' ,marginRight: '12px', color: 'gray' }}>
          <IconWithTooltip iconClassName={item?.className} />
        </div>
      <div style={{ display: 'flex', flexDirection:'column', alignItems: 'left', marginBottom: '4px' }}>
        <Typography variant="body2" style={{ fontSize: '14px', color: 'gray' }}>
          {item.title}
        </Typography>
      <Typography variant="body2" style={{ fontSize: '12px', color: 'gray' }}>
        {item.description}
      </Typography>
      </div>
    </div>
  ))}
    </Paper>
    </Popper>
    </div>
    </div>
      <Divider style={{ margin: '10px 0 0 0' }} />
  
    </>
    }
      </Stack>
      </>
    
  );
}

export default CustomBreadCrumbs