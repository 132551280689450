import { cloneDeep, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
    CustomButtonGroup,
    useAppDispatch,
    useAppSelector,
    CustomDatePicker,
    RadioButton,
    CustomInput,
    CustomBreadCrumbs,
    CustomRadioForm,
    FilterProp
} from '../../../globalUtils/globalExports';

import {
    getReferenceDataReducer,
    getChargerReducer,
    fetchChargersByCustomersByOrg,
    getUserReducer,
    fetchAddParameterRefChargers,createChargerUpdateCommands,updateChargerUpdateCommand,
    fetchSoftwarVersionReferenceData,
    fetchChargerStatusReferenceData,
    fetchChargerStationReferenceData
} from '../../../rmsReduxStore/reduxExports';
import { Id, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import './AddUpdateCommand.css';
import 'react-datepicker/dist/react-datepicker.css';
import { createBreadCrumbProps, useEffectOnce } from '../../../globalUtils/globalHooks'
import { IconWithTooltip } from '../../../globalUtils/globalIcons';
import { FormLabel } from '@mui/material';
import localStorageInstance from '../../../rmsReduxStore/api-service/LocalStorageService';
import { useLocation } from 'react-router-dom';

interface IParams {
    remoteCommand: string;
    ftpUrl: string;
}

interface UpdateCommandFormData {
    params: IParams[];
    chargers: string[];
    scheduledAt?: Date;
    sendNow: boolean;

}
const AddUpdateCommands: FC = () => {
    const initialFormData: UpdateCommandFormData = {
        params: [],
        chargers: [],
        sendNow: true,
        scheduledAt:new Date()
    }
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location= useLocation();
    const [initialUpdateCommandFormData, setInitialUpdateCommandFormData] = useState(initialFormData)
    const [updateCommandFormData, setUpdatedCommandFormData] = useState(initialFormData);
    const [mode, setMode] = useState(location?.state?.mode)
    const { addParameterRefChargers, addParameterRefChargingStations, } = useAppSelector(getChargerReducer);
    const {chargerListByCustomerByOrg}= useAppSelector(getUserReducer);
    const { customerReferenceData, chargingStationReferenceData, softwareVersionReferenceData, chargerStatusReferenceData } = useAppSelector(getReferenceDataReducer);
    const [chargingStationOptions, setChargingStationOptions] = useState<{ label: string; value: string; }[]>([]);
    const [chargerOptions, setChargerOptions] = useState<{ label: string; value: string; chargerId: string; }[]>([]);
    const [parameterValues, setParameterValues] = useState<IParams[]>([]);
    const [remoteCommand, setRemoteCommand] = useState('');
    const [chargersChipValues, setChargersChipValues] = useState<string[]>([]);
    const [chargerCheckboxStates, setChargerCheckboxStates] = useState<string[]>([]);
    const [isSelectAllChargersChecked, setIsSelectAllChargersChecked] = useState(false)
    const [selectedRadioOption, setSelectedRadioOption] = useState('SelectChargers');
    const [selectedChargers, setSelectedChargers] = useState<string[]>();  
    const editCommandData= location?.state?.commandData;
    const [selectedbtn, setSelectedbtn] = useState(editCommandData && editCommandData?.status==='Scheduled' ? 'laterBtn': 'nowBtn');
    const [selectedDate, setSelectedDate] = useState(editCommandData && new Date(editCommandData?.triggerTime )|| null);
    const initialParameterFormData = {
        customer: [],
        chargers: [],
        chargingStation: [],
        sendNow: true,
        params: [],
    };
    const [dataList, setDataList] = useState<string[]>([])

    const [filters, setFilters] = useState<InnerFilterState[]>(
        [
            {
                key: 'customer',
                value: null
            },
            {
                key: 'chargingStationId',
                value: null
            },
            {
                key: 'chargerStatus',
                value: null
            },
            {
                key: 'softwareVersion',
                value: null
            }
        ]
    )
    useEffectOnce(()=>{
        dispatch(fetchSoftwarVersionReferenceData());
        dispatch(fetchChargerStatusReferenceData());
        dispatch(fetchChargerStationReferenceData());
    })
    useEffect(()=>{
     loadInitialData();
    },[editCommandData,mode])

    const loadInitialData = ()=>{
        if(editCommandData){
            setMode('Edit')
            const commandDetail ={
                params: [
                    {
                        remoteCommand: 'ftp_software_upgrade',
                        ftpUrl: editCommandData?.value
                    }
                ],
                chargers: editCommandData?.devices?.map((charger)=>charger.chargerVisibleId),
                sendNow: editCommandData?.status==='Scheduled' ? false :true,
                scheduledAt:new Date(editCommandData?.triggerTime)
            }
            setUpdatedCommandFormData({
                ...commandDetail
            })
            setInitialUpdateCommandFormData({
                ...commandDetail
            })
            setSelectedbtn(commandDetail?.sendNow ? 'nowBtn':'laterBtn')
            setRemoteCommand(editCommandData?.value)
            setChargerCheckboxStates(commandDetail?.chargers)
            setSelectedChargers(commandDetail?.chargers);
            setChargersChipValues(commandDetail?.chargers?.length == dataList?.filter((item) => !item.endsWith(')'))?.length ? [] : commandDetail?.chargers);
            setIsSelectAllChargersChecked(commandDetail?.chargers?.length == dataList?.filter((item) => !item.endsWith(')'))?.length)
        }
    }
    useEffect(() => {
        if (selectedChargers) {
          const formData = cloneDeep(updateCommandFormData);
          formData.chargers = selectedChargers;
          setUpdatedCommandFormData(formData)
        }
      }, [selectedChargers]);

    useEffect(() => {
        if (dataList?.length > 0) {
            setChargersChipValues(chargerCheckboxStates?.length == dataList?.filter((item) => !item.endsWith(')'))?.length ? [] : chargerCheckboxStates);
            setIsSelectAllChargersChecked(chargerCheckboxStates?.length == dataList?.filter((item) => !item.endsWith(')'))?.length)
        }
    }, [dataList])

    useEffect (()=>{
        const orgId = localStorageInstance.fetchUser()?.orgId
        dispatch(fetchChargersByCustomersByOrg({
            orgId: orgId ? orgId : '',
            filters:  [],
            searchTerm:  ''
        }));
        dispatch(fetchAddParameterRefChargers({}));
    },[])


    useEffect(() => {
        const chargerData = chargerListByCustomerByOrg?.map((item) => {
          return {
            header: item?.customerName,
            count: item?.chargers?.length,
            data: item?.chargers?.map((charger) => charger?.visibleId)
          }
        }) || []
    
        const dataList = chargerData?.flatMap(({ header, count, data }) => [
          (header !== undefined && count !== undefined) ? `${header} (${count})` : '',
          ...data
        ]).filter(item => item !== '');
        const uniqueDataList = Array.from(new Set(dataList));
        setDataList(uniqueDataList)
      }, [chargerListByCustomerByOrg])

    const handleDateTimeChange = (date) => {
        const currentDate = new Date();
        if (date >= currentDate) {
            setSelectedDate(date);
            handleFormDataChange(date, 'scheduledAt');
        }
    };
    const handleRemoteCommandChange = (value: string) => {
        setRemoteCommand(value)
        setUpdatedCommandFormData((prevState) => ({
            ...prevState,
            params: [
                {
                    remoteCommand: 'ftp_software_upgrade',
                    ftpUrl: value
                }
            ],
        }));
    }
    const handleFormDataChange = useCallback((val, formKey) => {
        const formData = cloneDeep(updateCommandFormData);
        if (val?.id) {
            formData[formKey] = val?.id;
        }
        else {
            formData[formKey] = val;
        }
        setUpdatedCommandFormData(formData);
    }, [updateCommandFormData, addParameterRefChargingStations, addParameterRefChargers, chargingStationOptions, chargerOptions, handleDateTimeChange, parameterValues]);

    const resetFormData = useCallback(() => {
        setUpdatedCommandFormData(initialParameterFormData);
        setChargingStationOptions([]);
        setChargerOptions([]);
        setParameterValues([]);
    }, [])


    const disableAddButton = useCallback(() => {
        if (mode === 'Edit' && isEqual(updateCommandFormData, initialUpdateCommandFormData)) {
            return true
          }
        return (!(updateCommandFormData?.chargers?.length > 0 && remoteCommand?.length>0))
        
    }, [updateCommandFormData,initialFormData])

    const commandAddedToast = (): Id => toast.success('Command added.');
    const commandUpdatedToast = (): Id => toast.success('Command updated.');
    const somethingWentWrongToast = (): Id => toast.warn('Something went wrong.');
    const btnsList = useMemo(() => {
        return [
            {
                buttonText:mode==='Edit' ? 'Update':'Perform',
                buttonId: 'add',
                btnClassName: disableAddButton() ? 'primary__btn disabled' : 'primary__btn',
                handleClick: async (): Promise<void> => {
                    if(mode==='Edit'){
                        const updateBody ={
                            id:editCommandData?.id,
                            params:updateCommandFormData?.params,
                            chargers:addParameterRefChargers?.filter(charger => updateCommandFormData?.chargers.includes(charger?.visibleId)).map(charger => charger.id),
                            sendNow:updateCommandFormData?.sendNow,
                            scheduledAt:updateCommandFormData?.scheduledAt
    
                        }
                        const response: AxiosResponse = await dispatch(updateChargerUpdateCommand(updateBody));
                        if (response?.status === 200 || response?.status === 202) {
                            commandUpdatedToast();
                            setTimeout(() => {
                                navigate('/update-commands');
                            }, 2000)
                        }
                        else {
                            somethingWentWrongToast();
                            setTimeout(() => {
                            resetFormData();
                            }, 2000)
                        }
                    }
                    else{
                        const addBody ={
                            params:updateCommandFormData?.params,
                            chargers:addParameterRefChargers?.filter(charger => updateCommandFormData?.chargers.includes(charger?.visibleId)).map(charger => charger.id),
                            sendNow:updateCommandFormData?.sendNow,
                            scheduledAt:updateCommandFormData?.scheduledAt
    
                        }
                        const response: AxiosResponse = await dispatch(createChargerUpdateCommands(addBody));
                        if (response?.status === 200 || response?.status === 202) {
                            commandAddedToast();
                            setTimeout(() => {
                                navigate('/update-commands');
                            }, 2000)
                        }
                        else {
                            somethingWentWrongToast();
                            setTimeout(() => {
                                resetFormData();
                            }, 2000)
                        }
                    }

                },

                isDisabled: disableAddButton(),
                buttonVariant: 'filled',
            },

        ];
    }, [updateCommandFormData, disableAddButton]);

    const radioButtonList = [
        { radioButtonLabel: 'All Chargers', radioButtonId: 'AllChargers', isDisabled: false },
        { radioButtonLabel: 'Select Chargers', radioButtonId: 'SelectChargers', isDisabled: false },
    ]

    const breadCrumbs = createBreadCrumbProps({
        breadCrumbProps:
            [
                {
                    objectType: 'link',
                    id: 'addUpdateCommands',
                },
                {
                    objectType: 'text',
                    id: 'text',
                    text: 'Set Remote Command'
                }
            ]
    })

    const handleRadioOptionChange = (value) => {
        setSelectedRadioOption(value);
    };

    const radioButtonConfiguration = useMemo(() => {
        return {
            radionBtnGrpName: 'user__form_charger_options',
            buttonsList: radioButtonList,
            selectedRadioBtnValue: selectedRadioOption,
            handleSelectedRadioBtnChange: handleRadioOptionChange
        };
    }, [updateCommandFormData, selectedRadioOption, chargerCheckboxStates]);

    //TODO: Search Box Configuration *****
    const onSearchTextChange = useCallback((val) => {
        chargerListCallback?.(filters, val)
    }, [filters, updateCommandFormData])

    const searchBoxConfiguration = useMemo(() => {
        return {
            searchFieldId: 'user__form_chargers-search-box',
            searchFieldName: 'user__form_chargers-search-box',
            isDisabled: false,
            handleSearch: onSearchTextChange,
        };
    }, [onSearchTextChange, filters, updateCommandFormData]);

    const handleSelectedValues = (selectedChargers, selectedChargerChips, isChecked) => {
        setChargerCheckboxStates(selectedChargers)
        setSelectedChargers(selectedChargers)
        setChargersChipValues(selectedChargerChips);
        setIsSelectAllChargersChecked(isChecked)
    };
    const chargerListCallback = useCallback((filters?, searchTerm?) => {
        const orgId = localStorageInstance.fetchUser()?.orgId
        let filtersToBeSent: Filters[] = []
        if (filters && filters?.length > 0) {
            filtersToBeSent = filters?.map((item) => {
                return {
                    key: item.key,
                    values: item.value?.map((item) => item.id) || []
                }
            })
        }
        dispatch(fetchChargersByCustomersByOrg({
            orgId: orgId ? orgId : '',
            filters: filters ? filtersToBeSent : [],
            searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : ''
        }));
    }, [])
    //  TODO: CHARGERS FILTERS *****
    const handleFilterDataChange = useCallback((val, filterkey) => {
        const filtersToUpdate = cloneDeep(filters);
        const selectedFilter = filtersToUpdate?.find((filter) => filter?.key === filterkey);
        if (selectedFilter) {
            selectedFilter.value = Array.isArray(val) ? val : [val]
        }
        setFilters(filtersToUpdate);
        chargerListCallback?.(filtersToUpdate, '')
    }, [filters, chargerListCallback, setFilters, updateCommandFormData])

    const chargerFilterProps: FilterProp[] = useMemo(() => {
        return [
            {
                filterLabel: 'Customer',
                filterType: 'dropdown',
                filterId: 'Customer',
                filterDropdownProps: {
                    selectValue: filters?.find((filter) => filter?.key === 'customer')?.value,
                    selectOptions: customerReferenceData ?? [],
                    handleSelectOptionChange: (val): void => {
                        handleFilterDataChange(val, 'customer')
                    },
                    selectDropDownId: 'charger-customer-filter-dropdown',
                    isMultiSelect: true,
                    isDisabled: false,
                    renderToBody: true
                }
            },
            {
                filterLabel: 'Charging Station',
                filterType: 'dropdown',
                filterId: 'chargingStationId',
                filterDropdownProps: {
                    selectValue: filters?.find((filter) => filter?.key === 'chargingStationId')?.value,
                    selectOptions: chargingStationReferenceData ?? [],
                    handleSelectOptionChange: (val): void => {
                        handleFilterDataChange(val, 'chargingStationId')
                    },
                    selectDropDownId: 'charger-customer-filter-dropdown',
                    isMultiSelect: true,
                    isDisabled: false,
                    renderToBody: true
                }
            },
            {
                filterLabel: 'Software Version',
                filterType: 'dropdown',
                filterId: 'softwareVersion',
                filterDropdownProps: {
                    selectValue: filters?.find((filter) => filter?.key === 'softwareVersion')?.value,
                    selectOptions: softwareVersionReferenceData ?? [],
                    handleSelectOptionChange: (val): void => {
                        handleFilterDataChange(val, 'softwareVersion')
                    },
                    selectDropDownId: 'charger-customer-filter-dropdown',
                    isMultiSelect: true,
                    isDisabled: false,
                    renderToBody: true
                }
            },
            {
                filterLabel: 'Charger Status',
                filterType: 'dropdown',
                filterId: 'chargerStatus',
                filterDropdownProps: {
                    selectValue: filters?.find((filter) => filter?.key === 'chargerStatus')?.value,
                    selectOptions: chargerStatusReferenceData ?? [],
                    handleSelectOptionChange: (val): void => {
                        handleFilterDataChange(val, 'chargerStatus')
                    },
                    selectDropDownId: 'charger-customer-filter-dropdown',
                    isMultiSelect: true,
                    isDisabled: false,
                    renderToBody: true
                }
            },
        ]
    }, [customerReferenceData, chargingStationReferenceData,chargerStatusReferenceData,softwareVersionReferenceData,filters,])

    const sendNowLaterbtnsList = [
        { radioButtonLabel: 'Now', radioButtonId: 'nowBtn' },
        { radioButtonLabel: 'Later', radioButtonId: 'laterBtn' },
    ]

    const handleSendLaterChange = (event, value) => {
        setSelectedbtn(value);
        if (value === 'nowBtn') {

            handleFormDataChange(null, 'scheduledAt');
            setUpdatedCommandFormData((prevState) => ({
                ...prevState,
                sendNow: true,
            }));
        } else {
            setUpdatedCommandFormData((prevState) => ({
                ...prevState,
                sendNow: false,
            }));
        }
    };
    return (
        <>
            <div className='user__form__wrap'>


                <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
                <div className='add-parameter-content-wrap'>
                    <div className='remote-command-content-wrap-top'>
                        {/* <div className='add-remote-command-heading'>
                            Remote Command
                        </div> */}
                        <div className="remote-commands-ftp-software-upgrade-heading-container">
                            <IconWithTooltip iconClassName='panel_ftp_software_connector_icon' />
                            <div> FTP Software Upgrade</div>
                        </div>
                        <div className="charger__remote__commands_dashboard-content">
                            <FormLabel className={'rms-form-label'} required>
                                URL
                            </FormLabel>
                            <CustomInput
                                inputPlaceHolder="Paste Url"
                                handleInputChange={(e) => { handleRemoteCommandChange(e.target.value) }}
                                inputValue={remoteCommand}
                                inputFieldId="remote-command"
                                isRequired={true}
                            />
                        </div>
                    </div>
                    <div className='update_command_body'>
                        <div className='user__form__wrap-content'>

                            <CustomRadioForm
                                formContainerClass={'update_remote_command_charger-selection'}
                                header={'Select Chargers'}
                                dataList={dataList || []}
                                individualCheckboxStates={chargerCheckboxStates}
                                selectedChipValues={chargersChipValues}
                                selectAllChecked={isSelectAllChargersChecked}
                                handleSelectedData={handleSelectedValues}
                                filterProps={chargerFilterProps}
                                radioButtonConfiguration={radioButtonConfiguration}
                                searchBoxConfiguration={searchBoxConfiguration}
                                disableCheckBox={false}
                                showRadioList={true}
                                radioButtonConfigNotRequired={true}
                            />
                        </div>
                    </div>

                    <div className='add__parameter__btns'>
                        <div className='send-now-and-later-box'>
                            <RadioButton
                                buttonsList={sendNowLaterbtnsList}
                                handleSelectedRadioBtnChange={handleSendLaterChange}
                                selectedRadioBtnValue={selectedbtn}
                                radionBtnGrpName="customerOptions"
                            />
                            {selectedbtn === 'laterBtn' && (
                                <div className='add-parameter-scheduled-date'>
                                    <CustomDatePicker
                                        dateFormat='dd/MM/yyyy HH:mm'
                                        startDate={selectedDate}
                                        showTimePicker={true}
                                        endDate={null}
                                        selectsRange={false}
                                        onChange={handleDateTimeChange}
                                        datePickerClassName={'add_parameter_scheduled_date_time'}
                                        timeInterval={15}

                                    />
                                </div>
                            )}
                        </div>
                        <CustomButtonGroup buttonsList={btnsList} buttonGroupClassName='button__group__footer' />
                    </div>

                </div>
            </div>

        </>
    );
};

export default AddUpdateCommands;

