import React, { memo, useCallback, useEffect, useState } from 'react'
import { DashboardLoader, ParametersTable, useAppDispatch, useAppSelector } from '../../../globalUtils/globalExports'
import { useLocation } from 'react-router-dom'
import { fetchChargerSettingsMasterInfo, fetchChargerSettingsSystemInfo, getChargerReducer, updateChargerSettingsParameters } from '../../../rmsReduxStore/reduxExports'
import { cloneDeep } from 'lodash'
import { updateMasterInfo, useEffectOnce, updateSettingsValueByKey } from '../../../globalUtils/globalHooks'
import { toast } from 'react-toastify'

interface ChargerPanelSettings {
  contentHeight: number,
}
interface IEditableParametersDropDownValues {
  id: string, value: string, label: string, name: string
}


const ChargerPanelSettingsDashboard = memo(function ChargerPanelSettingsDashboard({ contentHeight }: ChargerPanelSettings) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { chargerSettingsMasterInfo, chargerSettingsSystemInfo } = useAppSelector(getChargerReducer);
  const [settingsData, setSettingsData] = useState<ChargerMasterInfo[] | null>(null);
  const [settingsInitialData, setSettingsInitialData] = useState<ChargerMasterInfo[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showLoader, setShowLoader] = useState(false);


  useEffect(() => {
    if (chargerSettingsMasterInfo !== null && chargerSettingsSystemInfo !== null) {
      const chargerMasterData = cloneDeep(chargerSettingsMasterInfo);
      const chargerSystemData = cloneDeep(chargerSettingsSystemInfo);
      const data = updateMasterInfo(chargerMasterData, chargerSystemData);
      setSettingsData(data);
      setSettingsInitialData(data);
    }
  }, [chargerSettingsMasterInfo, chargerSettingsSystemInfo])

  useEffectOnce(() => {
    dispatch(fetchChargerSettingsMasterInfo({ chargerId: location?.state?.chargerId }))
    dispatch(fetchChargerSettingsSystemInfo({ chargerId: location?.state?.chargerId }))
  })

  // Method: Update the value of parameters and check the validations for Input field while editing settings parameters
  const handleChange = useCallback((key, value, subParameter) => {
    const existingData = cloneDeep(settingsData)
    const data = updateSettingsValueByKey(existingData, 'key', key, value)
    setSettingsData(data)
    if (subParameter?.fieldType === 'Input') {
      const inValidInputErrorMsg = checkValidationsAndShowError(subParameter, value)
      setErrorMessage(inValidInputErrorMsg != null ? inValidInputErrorMsg : '')
    }
  }, [settingsData])

 // Method: Called when user cancel the changes made on settings Parameters
  const handleCancel = useCallback(() => {
    const existingData = cloneDeep(settingsInitialData)
    setSettingsData(existingData)
    setErrorMessage('')
  }, [settingsInitialData])

 // Method: Called when user confirm while changing settings Parameters
  const handleSubmit = async (subParameter): Promise<void> => {
    let finalValue : string | number = removeParameterValueUnit(subParameter)
    if (subParameter?.dataType === 'Integer' && subParameter?.fieldType === 'Input') {
     finalValue = parseInt(finalValue); // Converting parameter value to Integer
    } else if (subParameter?.dataType === 'Double' && subParameter?.fieldType === 'Input') {
      finalValue = parseFloat(finalValue).toFixed(subParameter?.precision || 2) // Converting parameter value to Double and limiting it to either (precision) or 2 decimal points
    }
    const reqBody = {
      chargerId: location?.state?.chargerId,
      key: subParameter?.key,
      values: Array.isArray(finalValue) ? finalValue : [finalValue] // server needs values as Array
    }
    setShowLoader(true);
    const response = await dispatch(updateChargerSettingsParameters(reqBody));
    if (response && response) {
      setShowLoader(false);
      if (response?.updated) {
      toast.success('Setting updated on the charger.');
      } else {
        toast.error(response?.message);
      }
      dispatch(fetchChargerSettingsSystemInfo({ chargerId: location?.state?.chargerId }))
    }
  }

  // Method: Allowing only numbers for dataType (Integer/Double) in Input field while changing settings parameters
  const keyPressValidation = (event, dataType): void => {
    const { key, target } = event;
    // const charCode = event.which ? event.which : event.keyCode;
    if (dataType === 'Integer') {
      if (key === ' ' || (isNaN(Number(key)) && key !== 'Backspace' && key !='ArrowRight' && key!='ArrowLeft' && !(event.metaKey || event.ctrlKey && key.toLowerCase() === 'v'))) event.preventDefault();
    } else if (dataType === 'Double') {
      if (key === '.' && target?.value?.includes('.')) event.preventDefault()
      if (key === ' ' || (isNaN(Number(key)) && key !== '.' && key !== 'Backspace'&& key !='ArrowRight' && key!='ArrowLeft' && !(event.metaKey || event.ctrlKey && key.toLowerCase() === 'v'))) event.preventDefault();
    }
  }

  return (
    <div className="charger__panel__systems__info__dashboard" style={{ height: `${contentHeight}px` }}>
      <DashboardLoader handleClose={setShowLoader} showLoader={showLoader} loaderText = {'Changing the setting on the charger.'} />
      <ParametersTable
        data={settingsData}
        containerClassName={'charger__panel__settings-paramters-table'}
        handleChange={handleChange}
        handleCancel={handleCancel}
        errorMessage={errorMessage}
        keyPressValidation={keyPressValidation}
        handleSubmit={handleSubmit} />
    </div>
  )
}
)

export default ChargerPanelSettingsDashboard


// Helper Methods for Charger Settings Parameters

/* Helper Method: Returns the array of possible values for 
     DropDown field while editing settings prameters */
export const parseDropDownValues = (possibleValues): Array<IEditableParametersDropDownValues> => {
  const parsedValues = JSON.parse(possibleValues)
  const output: Array<IEditableParametersDropDownValues> = [];
  if (parsedValues) {
    for (const key in parsedValues) {
      output.push({
        id: key,
        value: parsedValues[key],
        label: parsedValues[key],
        name: parsedValues[key]
      });
    }
  }
  return output
}

/* Helper Method: Returns the Enable/Disable toggle label based on possible values
      while editing settings parameters */
export const parseToggleLabels = (possibleValues, type): string => {
  const parsedValues = JSON.parse(possibleValues)
  if (type === 'Enable') {
    return parsedValues[1] ? parsedValues[1] : 'Enabled'
  } else {
    return parsedValues[0] ? parsedValues[0] : 'Disabled'
  }

}

/* Helper Method: Checks the validations for Input Field based on possible values and 
   returns the appropriate error message while editing settings parameters */
export const checkValidationsAndShowError = (subParameter, value): string | null => {
  console.log('error',subParameter);
  const parsedValues = JSON.parse(subParameter?.possibleValues)
  console.log(parsedValues,subParameter,'error');
  if (subParameter?.dataType === 'String') {
    return parsedValues && value?.length >= parsedValues['max'] ? `Only ${parsedValues['max']} characters are allowed` : null
  } else if (subParameter?.dataType === 'Integer') {
    return parsedValues && !(parseInt(value) >= parsedValues['min'] && parseInt(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.` : null
  } else if (subParameter?.dataType === 'Double') {
    return parsedValues && !(parseFloat(value) >= parsedValues['min'] && parseFloat(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.` : null
  }
  return null
}

/* Helper Method: Removes the unit (%/V/A etc) from the settings parameter value 
   while editing and sending to server if any */
export const removeParameterValueUnit = (subParameter): string => {
  if (subParameter?.values !== null && subParameter?.values !== undefined) {
    return subParameter?.unit && subParameter?.unit !== 'NA' ? subParameter?.values?.replaceAll(subParameter?.unit, '') : subParameter?.values
  }
  return ''
}


export const renderDropDownSelectedValue = (subParameter) => {
  if (subParameter?.fieldType === 'DropDownMultiple') { 
      const values = typeof subParameter?.values == 'string' ? subParameter?.values?.split(',') : subParameter?.values;
      return parseDropDownValues(subParameter?.possibleValues).filter((item) => values?.includes(item.value))
  }
  return parseDropDownValues(subParameter?.possibleValues).find((item) => item.value === subParameter?.values)
}  

