import { cloneDeep, parseInt } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  DashboardHeader,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  CustomForm, CustomButtonGroup, CustomBreadCrumbs, DashboardLoader, CustomRadioForm, FilterProp, CheckBox,
  SidePopover
} from '../../globalUtils/globalExports';
import {
  getReferenceDataReducer, getUserReducer,
  fetchChargerProductTypeReferenceData, fetchAlarmRules, createAlarmRule, fetchAddParameterRefChargers, fetchChargersByCustomersByOrg, fetchChargerLocationReferenceData
} from '../../rmsReduxStore/reduxExports';
import { useNavigate } from 'react-router-dom';
import { ICustomFormProps, IFormContentProp } from '../../globalUtils/CustomForm/CustomForm';
import { useLocation } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import '../users/UserForm/UserForm.css'
import { AxiosResponse } from 'axios';
import { createBreadCrumbProps, } from '../../globalUtils/globalHooks';
import { CreateRuleAlertLogic, NotificationActionStatusSidePopover } from '../componentExports';
import { updateAlarmRule, fetchSingleRule } from '../../rmsReduxStore/configureNotificationRedux/configureNotificationCreators';
// import { Icon } from '../../globalUtils/globalIcons';
import AddIcon from '@mui/icons-material/Add';
import './CreateRule.css'
import { IconWithTooltip } from '../../globalUtils/globalIcons';
import { Button } from '@mui/material';
// import { MuiChipsInput } from 'mui-chips-input';

interface CreateRuleFormData {
  name: string
  description: string
  chargerIds: string[]
  logicComplexity: 'Basic' | 'Advanced'
  alarmTypeId: string
  alarmContinuesFor: string
  alarmType: IAlarmType
  autoAssign:string
}
interface IAlarmType {
  severity: string,
  id: string,
  name: string
}

const CreateRule: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userRoleTypeReferenceData } = useAppSelector(getReferenceDataReducer);
  const { chargerListByCustomerByOrg } = useAppSelector(getUserReducer)
  const { chargerLocationReferenceData, chargerProductTypeReferenceData, chargingStationReferenceData, chargerVisibleIdReferenceData } = useAppSelector(getReferenceDataReducer);
  const initialFormData: CreateRuleFormData = {
    name: '',
    description: '',
    chargerIds: [],
    logicComplexity: 'Basic',
    alarmTypeId: '',
    alarmContinuesFor: '',
    alarmType: { severity: '', id: '', name: '' },
    autoAssign: 'Enabled'

  }
  const [CreateRuleFormData, setCreateRuleFormData] = useState(initialFormData);
  const [dataList, setDataList] = useState<string[]>([])
  const [mode, setMode] = useState(location?.state?.mode ? location?.state?.mode : 'Create')
  const { customerReferenceData } = useAppSelector(getReferenceDataReducer);
  const [showLoader, setShowLoader] = useState(false);
  const [alarmList, setAlarmList] = useState<IAlarmType[]>([]);
  const [showSms, setShowSms] = useState<boolean>(false);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const handleSetShowSms = (value)=>{
    setShowSms(value);
  }
  const handleSetShowEmail = (value)=>{
    setShowEmail(value);
  }
  const [smsRecipients,setSmsRecipients] = useState<string[]>([])
  const [emailRecipients,setEmailRecipients] = useState<string[]>([])
  const [selectedChargersInitially, setSelectedChargersInitially] = useState<string[]>();
  const [filters, setFilters] = useState<InnerFilterState[]>(
    [{
      key: 'customer',
      value: null
    },
    {
      key: 'chargingStationId',
      value: null
    },
    {
      key: 'productType',
      value: null
    },
    {
      key: 'location',
      value: null
    },])
  const [chargersChipValues, setChargersChipValues] = useState<string[]>([]);
  const [chargerCheckboxStates, setChargerCheckboxStates] = useState<string[]>([]);
  const [selectedRadioOption, setSelectedRadioOption] = useState('SelectChargers');
  const [selectedChargers, setSelectedChargers] = useState<string[]>();
  const [isSelectAllChargersChecked, setIsSelectAllChargersChecked] = useState(false)
  const orgId = JSON.parse(localStorage.getItem('rms-user') || '{}').orgId
  const [errorMessage, setErrorMessage] = useState('')
  const [isNotificationActionSidePopoverOpen,setIsNotificationActionSidePopoverOpen] = useState<boolean>(false);
  const handleClose = ()=> {
    setIsNotificationActionSidePopoverOpen(false);
  }

  const handleSmsRecipientsChange = (value) => {
    console.log(value,'check1')
    setSmsRecipients(value);
  }

  const handleEmailRecipientsChange = (value) => {
    setEmailRecipients(value);
  }

  useEffect(() => {
    const chargerData = chargerListByCustomerByOrg?.map((item) => {
      return {
        header: item?.customerName,
        count: item?.chargers?.length,
        data: item?.chargers?.map((charger) => charger?.visibleId)
      }
    }) || []

    const dataList = chargerData?.flatMap(({ header, count, data }) => [
      (header !== undefined && count !== undefined) ? `${header} (${count})` : '',
      ...data
    ]).filter(item => item !== '');
    const uniqueDataList = Array.from(new Set(dataList));
    setDataList(uniqueDataList)
  }, [chargerListByCustomerByOrg])

  const handleFetchChargerProductTypes = async () => {
    try {
      const res = await dispatch(fetchChargerProductTypeReferenceData());
      const response = JSON.parse(res);

      if (response.length) {
        return response
      }
    } catch (error) {
      console.error('Error fetching charger product types:', error);
    }
  };
  const handleFetchAlarmTypes = async () => {
    try {
      const res = await dispatch(fetchAlarmRules('false'));
      const response = JSON.parse(res);

      if (response.length) {
        setAlarmList(response);
        return response
      }
    } catch (error) {
      console.error('Error fetching charger product types:', error);
    }
  };

  useEffectOnce(async () => {
    dispatch(fetchChargerLocationReferenceData())
    chargerListCallback?.(orgId)
    setShowLoader(true);
    dispatch(fetchAddParameterRefChargers())
    setMode(location?.state?.mode || 'Create')
    const alarmResponse = await handleFetchAlarmTypes()
    const responseProductTypes = await handleFetchChargerProductTypes()

    console.log(alarmResponse, responseProductTypes)
    if (mode && location?.state?.userId || location?.state?.ruleId) {
      dispatch(fetchSingleRule({}, location?.state?.userId)).then((res) => {
        setSelectedChargersInitially(res?.data?.data?.chargerIds)
        setSelectedChargers(res?.data?.data?.chargerIds)
        setChargerCheckboxStates(res?.data?.data?.chargerIds)
        setChargersChipValues(res?.data?.data?.chargerIds)
        handleSmsRecipientsChange(res?.data?.data?.smsRecipients),
        handleEmailRecipientsChange(res?.data?.data?.emailRecipients),

        setCreateRuleFormData((prevState) => ({
          ...prevState,
          alarmTypeId: res?.data?.data?.alarmTypeId,
          alarmType: alarmResponse.filter(alarm => res?.data?.data?.alarmTypeId?.includes(alarm.id)),
          autoAssign: res?.data?.data?.autoAssign,
        }))

      })
    }
    setShowLoader(false)
  });
  
  useEffect(() => {
    if (location?.state?.rowData) {
      setCreateRuleFormData({
        ...CreateRuleFormData,
        name: location?.state?.rowData?.name || location?.state?.rowData?.ruleName,
        description: location?.state?.rowData?.description,
        alarmContinuesFor: location?.state?.rowData?.alarmContinuesFor,
      })
      if (location.state?.rowData?.notifyUsingEmail == 'Yes') {
        setShowEmail(true)
      }
      if (location.state?.rowData?.notifyUsingSms == 'Yes') {
        setShowSms(true)
      }
    }
  }, [])

  const handleFormDataChange = useCallback((val, formKey) => {
    const formData = cloneDeep(CreateRuleFormData);
    if (val?.id) {
      formData[formKey] = val?.id;
    }
    else {
      formData[formKey] = val;
    }
    setCreateRuleFormData(formData);

    if (formKey == 'name') {
      if (val.length > 90) {
        setErrorMessage('Max characters allowed are 90')
      }
      else setErrorMessage('')
      formData[formKey] = formData[formKey].charAt(0).toUpperCase() + formData[formKey].slice(1);

    }


    if (formKey == 'alarmType') {
      formData[formKey] = val;

      setCreateRuleFormData((prevState) => ({
        ...prevState,
        alarmTypeId: val?.id
      }))
    }
    else if (formKey == 'alarmContinuesFor') {
      if (val > 240) {
        toast.error('Max value can be 240 minutes.')
        formData[formKey] = 0
      }

    }
  }, [CreateRuleFormData]);

  console.log(CreateRuleFormData, 'formData')

  function arraysAreEqual<T>(arr1?: T[], arr2?: T[]): boolean {

    if (!arr2) {
      return false;
    }
    if (!arr1) {
      return false;
    }
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every((value, index) => value === arr2[index]);
  }
  const disableSubmitButton = useCallback(() => {

    if (showSms == false && showEmail == false) {
      return true
    }
    if (mode == 'Create') {
      return !(
        // Check that the name is alphanumeric and within the valid length
        (CreateRuleFormData?.name?.length > 0 &&
          /^[a-zA-Z0-9\s]*$/.test(CreateRuleFormData.name) && // Alphanumeric check
          CreateRuleFormData.name.length <= 150 // Max length check
        ) &&
        (showSms || showEmail) &&
        (selectedChargers && selectedChargers?.length > 0) &&
        (CreateRuleFormData.alarmTypeId) &&
        CreateRuleFormData?.description?.length <= 600 // Max total length check
      );
    }
    else if (mode == 'Edit') {

      const result = (CreateRuleFormData?.name == location.state?.rowData?.name) &&
        (CreateRuleFormData?.description == location.state?.rowData?.description) &&
        (arraysAreEqual(selectedChargers, selectedChargersInitially)) &&
        (CreateRuleFormData.alarmContinuesFor == location.state?.rowData?.alarmContinuesFor) &&
        (showEmail == (location.state?.rowData?.notifyUsingEmail == 'No' ? false : true) &&
          (showSms == (location.state?.rowData?.notifyUsingSms == 'No' ? false : true)))
      return result

    }



  }, [CreateRuleFormData, showSms, showEmail, selectedChargers]);

  const userFormSuccessToast = (): Id => toast.success(mode === 'Edit' ? 'Notification Rule Edited.' : 'Notification Rule Added.');

  const userFormErrorToast = (message): Id => toast.error(message);

  const footerButtonList = useMemo(() => {
    return [
      {
        buttonText: mode === 'Edit' ? 'Update' : 'Create',
        buttonId: 'submit',
        btnClassName: disableSubmitButton() ? 'primary__btn disabled' : 'primary__btn',

        handleClick: (): void => {
          handleSubmit?.(mode, showSms, showEmail)
        },
        isDisabled: disableSubmitButton(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          navigate('/configureNotification')
        },
        isDisabled: false,
        buttonVariant: 'outlined',
      },
    ];
  }, [disableSubmitButton, CreateRuleFormData, mode, showSms, showEmail, selectedChargers]);

  const createRuleFormArray: IFormContentProp[] = [
    {
      rowCountClassName: '',
      sectionHeading: '',
      formObject: [
        {
          formLabel: 'Notification Name',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Type here',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'name');
            },
            inputValue: CreateRuleFormData?.name,
            inputType: 'string',
            inputFieldId: 'add_user_name',
            isRequired: false,
            isDisabled: false,
            autoFocus: true,
            errorMessage: errorMessage
          },
          textAreaProps: null,
          isFixedWidth: true,

        },
        {
          formLabel: 'Description',
          isRequired: false,
          objectType: 'text-area',
          selectDropdownProps: null,
          inputProps: null,
          isFixedWidth: true,
          textAreaProps: {
            inputPlaceHolder: mode !== 'View' ? 'Type here' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'description');
            },
            isRequired: false,
            inputValue: CreateRuleFormData?.description,
            inputFieldId: 'description',
            isDisabled: false,
            className: 'custom-resize'
          },
        },

      ],
    },

    // {
    //   rowCountClassName: 'grid-2',
    //   sectionHeading: '',
    //   formObject: [
    //     {
    //       formLabel: 'Description',
    //       isRequired: false,
    //       objectType: 'text-area',
    //       selectDropdownProps: null,
    //       inputProps: null,
    //       textAreaProps: {
    //         inputPlaceHolder: mode !== 'View' ? 'Type here' : '',
    //         handleInputChange: (event): void => {
    //           handleFormDataChange(event?.target?.value, 'description');
    //         },
    //         isRequired: false,
    //         inputValue: CreateRuleFormData?.description,
    //         inputFieldId: 'description',
    //         isDisabled: mode === 'View',
    //         className: 'custom-resize'
    //       },
    //     },
    //   ],
    // },


  ];

  const createRuleFormProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: 'create__rule__name__description',
      formArray: createRuleFormArray
    };
  }, [CreateRuleFormData, userRoleTypeReferenceData, mode, customerReferenceData])


  const handleSubmit = useCallback(async (mode, sms, email) => {

    const reqBody = {
      name: CreateRuleFormData?.name,
      description: CreateRuleFormData?.description,
      chargerIds: selectedChargers,
      logicComplexity: CreateRuleFormData?.alarmContinuesFor?.length > 0 ? 'Advanced' : 'Basic',
      alarmTypeId: CreateRuleFormData?.alarmType?.id ? CreateRuleFormData?.alarmType?.id : CreateRuleFormData?.alarmTypeId,
      alarmContinuesFor: parseInt(CreateRuleFormData?.alarmContinuesFor),
      notifyUsingSms: sms ? 'Yes' : 'No',
      notifyUsingEmail: email ? 'Yes' : 'No',
      id: location?.state?.userId,
      autoAssign: CreateRuleFormData?.autoAssign,
      smsRecipients : smsRecipients,
      emailRecipients : emailRecipients,
    }
    console.log(smsRecipients,emailRecipients,'check2');

    let response: AxiosResponse

    if (mode === 'Edit') {
      setShowLoader(true)
      response = await dispatch(updateAlarmRule(reqBody))
    } else {
      setShowLoader(true)
      response = await dispatch(createAlarmRule(reqBody))
    }

    if ((response?.status === 200 || response?.status === 202) && response?.data?.message === 'Success') {
      console.log(response,'repsonse');
      setShowLoader(false)
      userFormSuccessToast();
      navigate('/configureNotification')
    } else {
      setShowLoader(false)
      userFormErrorToast(response?.data?.message);
    }
  }, [CreateRuleFormData, mode, selectedChargers,smsRecipients,emailRecipients])

  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'createNotificationRule',
        },
        {
          objectType: 'text',
          id: 'text',
          text: mode !== 'Create' ? CreateRuleFormData?.name : `${mode} Notification Rule`
        }
      ]
  })

  const setHeaderText = useCallback(() => {
    if (mode == 'Create') {
      return `${mode} Notification Rule`
    } else return CreateRuleFormData?.name
  }, [mode, CreateRuleFormData])

  const chargerListCallback = useCallback((orgId, filters?, searchTerm?) => {
    let filtersToBeSent: Filters[] = []
    if (filters && filters?.length > 0) {
      filtersToBeSent = filters?.map((item) => {
        return {
          key: item.key,
          values: item.value?.map((item) => item.id) || []
        }
      })
    }
    dispatch(fetchChargersByCustomersByOrg({
      orgId: orgId,
      filters: filters ? filtersToBeSent : [],
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : ''
    }));
  }, [CreateRuleFormData])

  const handleFilterDataChange = useCallback((val, filterkey) => {
    const filtersToUpdate = cloneDeep(filters);
    const selectedFilter = filtersToUpdate?.find((filter) => filter?.key === filterkey);
    if (selectedFilter) {
      selectedFilter.value = Array.isArray(val) ? val : [val]
    }
    setFilters(filtersToUpdate);
    chargerListCallback?.(orgId, filtersToUpdate, '')
  }, [filters, chargerListCallback, setFilters, CreateRuleFormData])

  const onSearchTextChange = useCallback((val) => {
    chargerListCallback?.(orgId, filters, val)
  }, [filters, CreateRuleFormData])

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'user__form_chargers-search-box',
      searchFieldName: 'user__form_chargers-search-box',
      isDisabled: mode === 'View',
      handleSearch: onSearchTextChange,
      searchBoxClassName: 'create__configure__notification__rule__charger_selection'
    };
  }, [onSearchTextChange, filters, CreateRuleFormData, mode]);

  const radioButtonList = [
    { radioButtonLabel: 'All Chargers', radioButtonId: 'AllChargers', isDisabled: mode === 'View' },
    { radioButtonLabel: 'Select Chargers', radioButtonId: 'SelectChargers', isDisabled: mode === 'View' },
  ]

  const chargerFilterProps: FilterProp[] = useMemo(() => {
    return [
      {
        filterLabel: 'Customer',
        filterType: 'dropdown',
        filterId: 'Customer',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'customer')?.value,
          selectOptions: customerReferenceData?.filter((customer) => customer?.id === 'all' || chargerVisibleIdReferenceData?.some((charger) => charger?.customerId === customer?.id)) ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'customer')
          },
          selectDropDownId: 'charger-customer-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true,
        }
      },
      {
        filterLabel: 'Location',
        filterType: 'dropdown',
        filterId: 'Location',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'location')?.value,
          selectOptions: chargerLocationReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'location')
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },

      {
        filterLabel: 'Charging Station',
        filterType: 'dropdown',
        filterId: 'chargingStationId',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'chargingStationId')?.value,
          selectOptions: chargingStationReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'chargingStationId')
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },
      {
        filterLabel: 'Product Type',
        filterType: 'dropdown',
        filterId: 'ProductType',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'productType')?.value,
          selectOptions: chargerProductTypeReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'productType')
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View',
          renderToBody: true
        }
      },
    ]
  }, [chargerLocationReferenceData, customerReferenceData, filters, mode, CreateRuleFormData])

  const handleSelectedValues = (selectedChargers, selectedChargerChips, isChecked) => {
    setChargerCheckboxStates(selectedChargers)
    setSelectedChargers(selectedChargers);
    setChargersChipValues(selectedChargerChips);
    setIsSelectAllChargersChecked(isChecked)
  };
  const handleRadioOptionChange = (_, value) => {
    setSelectedRadioOption(value);
    if (value === 'AllChargers') setSelectedChargers(['ALL'])
    else { setSelectedChargers(chargerCheckboxStates) }
  };

  const radioButtonConfiguration = useMemo(() => {
    return {
      radionBtnGrpName: 'user__form_charger_options',
      buttonsList: radioButtonList,
      selectedRadioBtnValue: selectedRadioOption,
      handleSelectedRadioBtnChange: handleRadioOptionChange

    };
  }, [CreateRuleFormData, mode, selectedRadioOption, chargerCheckboxStates]);

  const handleNotificationActionClick = () =>{
    setIsNotificationActionSidePopoverOpen(true)
  }

  const handleCloseNotificationActionStatusClosePopover = () => {
    setIsNotificationActionSidePopoverOpen(false)
  }

  // const [chips, setChips] = React.useState([])

  // const handleChange = (newChips) => {
  //   setChips(newChips)
  // }

  console.log(smsRecipients,emailRecipients,showSms,showEmail,'notification');

  return (
    <>
      <DashboardLoader showLoader={showLoader} />
      <div className='user__form__wrap configure__notification'>
        <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
        <div className='user__form__wrap-header-custom'>

          <DashboardHeader
            header={setHeaderText()}
            headerClassName='user__form__wrap-header-text'
          />
          {/* {mode !== 'Create' && (

            <Icon
              iconClassName={mode === 'View' ? 'rms__pencil__icon' : 'rms__view__icon'}
              containerClassName='org__form_header-edit'
              onIconClick={() => {
                // if (mode === 'Edit') {}
                setMode(mode === 'View' ? 'Edit' : 'View')
              }
              }
            />

          )} */}
        </div>
        <div className='user__form__wrap-content'>
          <CustomForm {...createRuleFormProps} />
          <CreateRuleAlertLogic
            selectDropdownProps={{
              selectValue: CreateRuleFormData?.alarmType,
              selectOptions: (alarmList),
              handleSelectOptionChange: (val): void => {
                handleFormDataChange(val, 'alarmType');
              },
              selectDropDownId: 'user-role-type-dropdown',
              selectDropDownName: 'user-role-type-dropdown',
              isDisabled: false,

              renderToBody: true,

            }}
            inputProps={{
              inputPlaceHolder: 'Type here',
              handleInputChange: (event): void => {
                handleFormDataChange(event?.target?.value, 'alarmContinuesFor');
              },
              inputValue: CreateRuleFormData?.alarmContinuesFor,
              inputType: 'string',
              inputFieldId: 'alarm_continues_for',
              isRequired: false,
              isDisabled: false,
            }}
            formLabel='Alarm'
            isRequired />
          <CustomRadioForm
            formContainerClass={'user__form__charger-selection'}
            header={'Charger Selection'}
            dataList={dataList || []}
            individualCheckboxStates={chargerCheckboxStates}
            selectedChipValues={chargersChipValues}
            selectAllChecked={isSelectAllChargersChecked}
            handleSelectedData={handleSelectedValues}
            filterProps={chargerFilterProps}
            radioButtonConfiguration={radioButtonConfiguration}
            smallerFont={true}
            searchBoxConfiguration={searchBoxConfiguration}
            disableCheckBox={mode === 'View'}
            showRadioList={selectedRadioOption === 'SelectChargers'}
            isCustom={true}
            radioButtonConfigNotRequired={true}
            removePadding={true}
          />

          
           <div className='user__form__wrap-footer-top'>
            <div className='user__form__wrap-footer-top-checkbox' onClick={() => { mode !== 'View' && handleFormDataChange(CreateRuleFormData?.autoAssign === 'Enabled' ? 'Disabled' : 'Enabled', 'autoAssign') }} >
              <CheckBox checked={CreateRuleFormData?.autoAssign === 'Enabled'} isDisabled={mode === 'View'} />
              Auto assign new charger to this user
            </div>
            <IconWithTooltip iconClassName='rms_info_icon-black' toolTipText={'RMS will automatically assign any new charger to this user.'} containerClassName='user__form_footer-auto-assign-info' />
          </div>

           {/* <CreateRuleNotificationAction
            showSms={showSms}
            showEmail={showEmail}
            setShowSms={setShowSms}
            setShowEmail={setShowEmail}
            disable={mode == 'View' ? true : false} */}
          {/* /> */}
          {(showSms === true)|| (showEmail === true) ?
           <div>
              <div className='custom__form__wrap-header-text-title row__label'>
                Notification Action
                <IconWithTooltip iconClassName={'define-execution-edit-pencil-sidebar edit__button'} onIconClick={handleNotificationActionClick}/>
              </div>
              {showSms && <div className='notification__action__row__label'> SMS : {smsRecipients && smsRecipients.length} Recipients</div>}
              {showEmail && <div className='notification__action__row__label'> Email : {emailRecipients && emailRecipients.length} Recipients</div>}
          </div> : <Button
            startIcon={<AddIcon />}
            sx={{ color: '#49c8d1', textTransform: 'none', fontSize: '1rem', marginTop: '30px' }}
            onClick={handleNotificationActionClick}
          >
            Notification Action
          </Button>}
          {/* <MuiChipsInput value={chips} onChange={handleChange} /> */}

        </div>
        <div className='user__form__wrap-footer'>
          <CustomButtonGroup
            buttonsList={mode === 'View' ? footerButtonList?.filter((button) => button?.buttonId === 'cancel') : footerButtonList}

            buttonGroupClassName='button__group__footer'
          />
        </div>
        
     </div>
     {isNotificationActionSidePopoverOpen && <SidePopover
        id='configure__notification__form__notification__action__status__popover'
        open={isNotificationActionSidePopoverOpen}
        headerText={'Notification Action Status'}
        containerClassName='configure__notification__detail__popover'
        handleClose={()=>{}}
        headerSecondaryText={'Email/SMS Message'}
        horizontalLinePopover={true}
        popperClassName='rule__engine__raise__a__ticket'
        isRuleEnginePopOver={true}
        headerClassName={'rule__engine__header__config'}
        ruleEngineSidePopoverOnclose={handleCloseNotificationActionStatusClosePopover}
        

        // eslint-disable-next-line react/no-children-prop
        children={
          <>
            {
              <NotificationActionStatusSidePopover
              
                handleClose={handleClose}
                showSms={showSms}
                handleSetShowSms={handleSetShowSms}
                showEmail={showEmail}
                handleSetShowEmail={handleSetShowEmail}
                smsRecipients={smsRecipients}
                handleSmsRecipientsChange={handleSmsRecipientsChange}
                emailRecipients={emailRecipients}
                handleEmailRecipientsChange={handleEmailRecipientsChange}
              />
            }
          </>
        }
      />
      }

    </>
  );
};

export default CreateRule;
