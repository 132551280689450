import { FormLabel } from '@mui/material';
import React, { FC } from 'react';
import './summaryPage.css'
import { CustomTooltipWithLabel } from '../../globalUtils/globalExports';

export interface ISummaryObject {
  label: string | null;
  hidden?: boolean;
  value: string | null
  isFullWidth?: boolean
  isAutoWidth?: boolean
}

export interface ISummaryContentProp {
  sectionHeading?: string;
  formObject: ISummaryObject[];
}

export interface ISummaryPageProps {
  formArray: ISummaryContentProp[];
  formContainerClassName: string;
}

const SummaryPage: FC<ISummaryPageProps> = (props) => {
  const { formArray } = props;
  return (
    <div className='summary_page_wrap_content'>
      {formArray && formArray?.length > 0 && formArray?.map((formItem, index) => {
        return (
          <div key={index} className='summary__section__content'>
            {(formItem?.sectionHeading && formItem?.sectionHeading?.length > 0) && <div className='summary__section__content_header'>{formItem?.sectionHeading}</div>}
            {formItem?.formObject && formItem?.formObject?.length > 0 && <div className='summary__section__content_row'>
              {formItem?.formObject?.map((object, index) => {
                return (
                  <>
                    {object?.value && <div key={'single' + index} className='summary__section__content__row__single' style={{ width: object?.isFullWidth ? '100%' : (object?.isAutoWidth ? 'auto' : '30%') }}>
                      {object?.label && <FormLabel className={'summary-page-label'}>{object?.label}</FormLabel>}
                      {object?.value && <CustomTooltipWithLabel containerClassName={'summary_section_label_value'} label={object?.value} labelId='summary_section_label_value' />}
                    </div>}
                  </>
                );
              })}
            </div>}
            {index !== formArray?.length - 1 && <hr className="custom-hr" />}
          </div>
        );
      })}
    </div>
  );
};

export default SummaryPage;