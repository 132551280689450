import React, { useCallback, useEffect, useState } from 'react';
import HighchartsTimeline from './HighchartsTimeline';
import HistoryPage, { HistoryPageProps } from './HistoryPage';
import { useEffectOnce } from '../../globalUtils/globalHooks';
import {
  useAppDispatch,
  useAppSelector,
} from '../../rmsReduxStore/rmsReduxHooks';
import {
  configureNotificationTypes,
  fetchNotificationHistoryTimelineData,
  getConfigureNotificationReducer,
} from '../../rmsReduxStore/reduxExports';
import { cloneDeep } from 'lodash';
import './HistorySidePopoverPage.css'
import { toast } from 'react-toastify';
import IconDatePicker from '../../globalUtils/IconDatePicker/IconDatePicker';

interface HistorySidePopOverProps extends HistoryPageProps {
  notificationId?: string;
}

export interface TimeLineDataPoint {
  y: number;
  name: number;
  x: number;
  color?: string;
  label?: string;
}

const HistorySidePopoverPage = function HistorySidePopoverPage(
  props: HistorySidePopOverProps
) {
  const {
    tableRows,
    totalCount,
    listLayoutCallBack,
    gridColumns,
    showLoader,
    pageNumber,
    tablePageSize,
    sortField,
    sortOrder,
    totalCountText,
    notificationId,
  } = props;
  const dispatch = useAppDispatch();
  const { configureNotificationHistoryTimeline } = useAppSelector(
    getConfigureNotificationReducer
  );
  const [timeLineDataPoints, setTimeLineDataPoints] = useState<
    TimeLineDataPoint[]
  >([]);
  const [timeLineFilter, setTimeLineFilter] = useState<Filters[]>([{ key: 'fireTime', values: ['one_week'] }])

  useEffectOnce(() => {
    graphCallBack();
  });

  useEffect(() => {
    calculateDataPointsFortimeLine();
  }, [configureNotificationHistoryTimeline]);

  const graphCallBack = useCallback((filters?) => {
    dispatch(
      fetchNotificationHistoryTimelineData({
        notificationId: notificationId,
        filters: filters ? filters : timeLineFilter,
      })
    );
  }, [timeLineFilter]);

  const tableLayoutCallBack = useCallback((pageNumber: number, pageSize: number, view: string, field: string, order: string) => {
    listLayoutCallBack?.(pageNumber, pageSize, view, field, order, timeLineFilter)
  }, [timeLineFilter, listLayoutCallBack])

  const calculateDataPointsFortimeLine = useCallback(() => {
    if (configureNotificationHistoryTimeline) {
      const dataPoints = configureNotificationHistoryTimeline?.response?.map(
        (singleData) => {
          return {
            x: new Date(singleData?.time),
            y: 0,
            name: singleData?.value,
          };
        }
      );

      setTimeLineDataPoints([
        {
          x: new Date(configureNotificationHistoryTimeline?.startDateTime),
          y: 0,
          name: 0,
        },
        ...dataPoints,
        {
          x: new Date(configureNotificationHistoryTimeline?.endDateTime),
          y: 0,
          name: 0,
        },
      ]);
    }
  }, [configureNotificationHistoryTimeline]);

  const filterData = [
    {
      filterText: 'Today',
      filterValue: 'one_day',
      filterTextClassName: '',
      handleClick: () => {
        handleFilterChange('one_day')
      },
    },
    {
      filterText: 'Last 7 days',
      filterValue: 'one_week',
      filterTextClassName: '',
      handleClick: () => {
        handleFilterChange('one_week')
      },
    },
    {
      filterText: 'Last 30 days',
      filterValue: 'one_month',
      filterTextClassName: '',
      handleClick: () => {
        handleFilterChange('one_month')
      },
    },
    {
      filterText: '',
      filterValue: 'custom',
      filterTextClassName: '',
    },
  ];

  const onDateChange = useCallback((dates) => {
    const [start, end] = dates;
    if (start && end) {
      const differenceInMilliseconds = end.getTime() - start.getTime();
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      if (differenceInDays > 30) {
        toast.error('Date range exceeded more than 30 days.');
        return
      }
    }
    if (start) {
      handleFilterChange('custom', start, end)
    } else {
      handleFilterChange('one_week')
    }
  }, [])

  const handleFilterChange = useCallback((val, start?, end?) => {
    let valuesToSet = [val]
    setTimeLineFilter((prevState) => {
      const previousFilters = cloneDeep(prevState)
      const filterToUpdate = previousFilters?.find((filter) => filter?.key === 'fireTime')
      if (filterToUpdate) {
        filterToUpdate.values = valuesToSet
        if (start) {
          valuesToSet = [val, start, end]
          filterToUpdate.values = valuesToSet
        }
        graphCallBack(previousFilters)
        listLayoutCallBack?.(pageNumber, tablePageSize, 'list', sortField, sortOrder, previousFilters)
        return previousFilters
      }
      return prevState
    })
  }, [])

  const notificationTimeLineCleanUpStates = useCallback(() => {
    const action = {
      type: configureNotificationTypes.CLEAR_CONFIGURE_NOTIFICATION_HISTORY_TIMELINE,
    }
    dispatch(action);
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', notificationTimeLineCleanUpStates);
    return (): void => {
      notificationTimeLineCleanUpStates()
      window.removeEventListener('beforeunload', notificationTimeLineCleanUpStates);
    }
  }, [])

  return (
    <div className='notification__history__popover'>
      <div className='timeline__quick__filter'>
        {filterData.map((filter, index) => {
          return (
            <div
              onClick={filter.handleClick}
              className='timeline__quick__filter__item'
              key={index}

            >
              <>
                {filter?.filterValue === 'custom' ?
                  <IconDatePicker
                    startDate={timeLineFilter?.find((filter) => filter?.key === 'fireTime')?.values?.[1] ? new Date(timeLineFilter?.find((filter) => filter?.key === 'fireTime')?.values?.[1] ?? '') : null}
                    endDate={timeLineFilter?.find((filter) => filter?.key === 'fireTime')?.values?.[2] ? new Date(timeLineFilter?.find((filter) => filter?.key === 'fireTime')?.values?.[2] ?? '') : null}
                    onChange={onDateChange}
                    selectsRange={true}
                    renderToBody={true}
                    popperPlacement='bottom-start'
                  /> :
                  <div
                    className={`${filter?.filterTextClassName}`}
                    style={{
                      fontWeight: timeLineFilter?.find((filter) => filter?.key === 'fireTime')?.values?.[0] === filter.filterValue ? 700 : 400,
                      cursor: 'pointer',
                    }}
                  >
                    {filter.filterText}
                  </div>
                }
                {index < filterData.length - 1 && <div>|</div>}
              </>
            </div>
          );
        })}
      </div>
      <HighchartsTimeline timeLineDataPoints={timeLineDataPoints} />
      <div style={{ height: 'calc(100dvh - 326px)', display: 'block' }}>
        <HistoryPage
          gridColumns={gridColumns}
          tableRows={tableRows}
          totalCount={totalCount}
          listLayoutCallBack={tableLayoutCallBack}
          showLoader={showLoader}
          totalCountText={totalCountText}
          pageNumber={pageNumber}
          tablePageSize={tablePageSize}
          sortField={sortField}
          sortOrder={sortOrder}
          leftPinnedColumns={['fireTime']}
        />
      </div>
    </div>
  );
};

export default HistorySidePopoverPage;
