import { ThemeProvider } from '@mui/material';
import React, { memo, ReactNode, useMemo, useRef, } from 'react';
import { alarmCardMapper, formatRelativeTimeForTable, LinkButton, TableDashboardTheme, TableLayout, useAppSelector } from '../../globalUtils/globalExports';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { chargerTypes, getRuleEngineReducer } from '../../rmsReduxStore/reduxExports';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { IconWithTooltip } from '../../globalUtils/globalIcons';
import { useNavigate } from 'react-router-dom';
import { NoDataComponentRuleEngine } from '../../globalUtils/TableDashboard/TableComponents';
const ExecutionSummarySidePopover = memo(function ExecutionSummarySidePopover(){

    const tableWrapRef = useRef<HTMLDivElement>(null)
    // const [tableWrapHeight, setTableWrapHeight] = useState(0)
    const { ruleEngineTableLoader} = useAppSelector(getRuleEngineReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // useEffect(() => {
    //     const calculateTableWrapHeight = (): void => {
    //       if (((tableWrapRef?.current) != null)) {
    //         const totalHeight = tableWrapRef?.current?.getBoundingClientRect?.().height
    //         const tableWrapHeight = totalHeight;
    //         setTableWrapHeight(tableWrapHeight)
    //       }
    //     }
    //     calculateTableWrapHeight()
    //     window.addEventListener('resize', calculateTableWrapHeight)
    //     return () => {
    //       window.removeEventListener('resize', calculateTableWrapHeight)
    //     }
    //   }, [])

      const tableColumnsHistory = useMemo(() => {
        return  [
          {
            field: 'fireTime',
            headerName: 'Fire Time',
            flex: 0.5,
            minWidth: 200,
            sortable: true,
            hideable: false,
            pinnable: true,
            renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
              const rowData = params?.row;
              return rowData?.fireTime ? formatRelativeTimeForTable(new Date(rowData?.fireTime)) : '-';
            },
          },
          {
              field: 'ruleName',
              headerName: 'Rule Name',
              flex: 1,
              minWidth: 120,
              sortable: true,
              hideable: false,
              pinnable: false,
              renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
              const rowData = params?.row;
              return (
                <div 
                className="active__alarms__name__wrap"
                onClick={(e)=>{
                  e.stopPropagation();
                      toast.info('Feature Coming Soon!')
                }}>
                    <div className="individual__charger__text">
                    <div className="alarm_name-text">
                    {rowData?.ruleName}
                </div>
                </div>
                </div>
              )
            },
          },
          {
            field: 'category',
            headerName: 'Category',
            flex: 1,
            minWidth: 110,
            sortable: true,
            renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
              const rowData = params?.row;
              if (rowData?.category) {
                const severityIcon = rowData?.category?.toUpperCase();
                const tooltipText = alarmCardMapper[severityIcon]?.title;
                return (
                <>
                <IconWithTooltip iconClassName={alarmCardMapper[severityIcon]?.alarmSummaryIcon} toolTipText={tooltipText}/>
                </> 
                );
              }
              else {
                return (
                  <>
                <IconWithTooltip iconClassName='trigger-parameters-sidebar' toolTipText={'Parameter'}/>
                </>
                )
              }
            },
          },
          {
            field: 'categoryTriggerLevel',
            headerName: 'Trigger Level',
            flex: 1,
            minWidth: 200,
            sortable: true,
              renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
                const rowData = params?.row;
                if (rowData?.category) {
                  return (
                  <div>
                    {rowData?.alarmName}
                  </div>
                  );
                }
                else {
                return (
                  <div>
                  {rowData?.parameterName}
                  </div>
                )
                }
              },
          },
          {
              field: 'chargerId',
              headerName: 'Charger',
              flex: 1,
              minWidth: 180,
              sortable: true,
              renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
                const rowData = params?.row;
                return (
                  rowData?.chargerId ?
                    (<div
                      className="individual__charger__wrap"
                      onClick={(e) => {
                        e?.stopPropagation()
                        const action = {
                          type: chargerTypes.SET_CHARGER_REDUCER_STATE,
                          isChargerTablePropsRetained: true
                        }
                        dispatch(action)
                      }
                      }
                    >
                      <LinkButton
                        linkBtnId={rowData?.chargerId}
                        link={'/charger?chargerId=' + rowData.chargerId}
                        btnClassName="individual__charger__text"
                        btnText={rowData?.chargerVisibleId}
                        linkBtnState={{ chargerId: rowData?.chargerId, charger: rowData }}
                      />
                    </div>) : (<div>{rowData?.chargerVisibleId}</div>)
                );
              },
          },
          {
            field: 'chargingStationName',
            headerName: 'Charging Station',
            flex:1,
            minWidth: 180,
            sortable: true,
            renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
              const rowData = params?.row;
              return (
                <div 
                className="active__alarms__name__wrap"
                onClick={(e)=>{
                  e.stopPropagation();
                      navigate('/station', { state: { id: rowData?.chargingStationId, mode: 'View', stationName: rowData?.chargingStationName, } });
                }}>
                    <div className="individual__charger__text">
                    <div className="alarm_name-text">
                    {rowData?.chargingStationName}
                </div>
                </div>
                </div>
              )
            },
          },
          {
                field: 'customerName',
                headerName: 'Customer',
                flex: 1,
                minWidth: 180,
                sortable: true,
                renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
                const rowData = params?.row;
                return (
                  <div 
                  className="active__alarms__name__wrap"
                  onClick={(e)=>{
                    e.stopPropagation();
                        navigate('/customer', { state: { id: rowData?.customerId, mode: 'View', name: rowData?.customerName } });
                  }}>
                              <div className="individual__charger__text">
                              <div className="alarm_name-text">
                      {rowData?.customerName}
                  </div>
                  </div>
                  </div>
                )
              },
          },
          {
              field: 'connectorType',
              headerName: 'Connector',
              flex: 1,
              minWidth: 180,
              sortable: true,
          },
          {
              field: 'actionStatus',
              headerName: 'Action Controls Status',
              flex: 0.8,
              minWidth: 300,
              sortable: true,
              renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
                const rowData = params?.row
                const val= rowData?.actionStatus  ||  {}
                const list: string[]=[];
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                list.push(...Object.entries(val).map(([key,value])=>(key as string)));
                const textVal  = list?.join(', ')
                return (
                    <>
                  <div>
                  {textVal}
                  </div>
                  </>
              ); 
              },
          },
          ]
        
        },[])
    return(
        <>
         <ThemeProvider theme={TableDashboardTheme}>
            <div ref={tableWrapRef}>
                <div
                className="rms__table__dashboard__table__wrap"
                // <div style={{ height: 'calc(100dvh - 290px)', display: 'block' }}>
                style={{ height: 'calc(100dvh - 120px)' }}
                >
                    <TableLayout
                    gridColumns={tableColumnsHistory}
                    tableRows={[{
                        id: '8723648952934',
                        fireTime: '29/09/2024 09:12:97',
                        ruleName: 'Testing',
                        category: 'Badiya'
                    }]} 
                    tableId={'rule__summary__execution'}
                    tableClassName={'rule__summary__execution'}
                    listLayoutCallBack={()=>{}}
                    totalCount={0}
                    showLoader={ruleEngineTableLoader}
                    showSkeleton={false}
                    totalCountText={'Rule'}
                    customNoDataComponent={NoDataComponentRuleEngine}
                    // pageNumber={ruleEngineListTableProps?.pageNumber}
                    // tablePageSize={ruleEngineListTableProps?.pageSize}
                    // sortField={ruleEngineListTableProps?.sortBy}
                    // sortOrder={ruleEngineListTableProps?.order}
                    // refreshId={forceRefreshId}
                    // columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'ruleEngine')}
                    // handleColumnPrefrenceChange={handleColumnPrefrenceChange}     
                    // leftPinnedColumns={['name']}         
                    /> 
                </div>
            </div>
        </ThemeProvider>   
        </>
    )
})

export default ExecutionSummarySidePopover;
