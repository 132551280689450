/* eslint-disable @typescript-eslint/ban-types */
import React, {  useState } from 'react';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import BoltIcon from '@mui/icons-material/Bolt';
import { Handle, Position } from '@xyflow/react';
import { SidePopover } from '../../../globalUtils/globalExports';
import { IfThenElseSidePopOver } from '../../componentExports';
import './ifThenElseNode.css'
import ExecutionPlan from './ExecutionPlan';
import CloseIcon from '@mui/icons-material/Close';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';

interface CustomNodeProps {
  label : string;
  executionPlan: ExecutionPlan;
  handleUpdateExecutionPlan: Function;
  updateEdgesAndNodes: Function;
}

const CustomNode: React.FC<CustomNodeProps> = ({ executionPlan,handleUpdateExecutionPlan,updateEdgesAndNodes } : CustomNodeProps) => {

  const [isIfElseSidePopOverOpen, setIsIfElseSidePopOverOpen] = useState(false);

  const handleEditClick = () => {
    setIsIfElseSidePopOverOpen(true)
  };
   
  const handleCloseIfElsePopover = () => {    
    setIsIfElseSidePopOverOpen(false);
  }

  return (
    <>
     <Paper
      elevation={3}
      sx={{
        border: '1px solid #8a2be2',
        borderLeft: '6px solid #8a2be2', 
        borderRadius: '8px',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        minWidth: '500px',
        backgroundColor: '#fff',
        position: 'relative',
      }}
    >
        <Handle type="target" className='transparent-handle' position={Position.Top}
          // style={{ left: '28.5%', transform: 'translateX(-50%)' }}
        />
      <Handle type="source"  className='transparent-handle' position={Position.Left} />

      <div style={{ display: 'flex', flexDirection: 'column',width:'-webkit-fill-available' }}>
        <div style={{display:'flex',marginBottom:'10px',alignItems:'center'}}>

      <IconWithTooltip iconClassName={'execution_plan_button_define'} />
            
      <Box display="flex" alignItems="center" marginRight="8px">
        <Typography
          variant="subtitle2"
          sx={{ fontSize: '1rem', color: '#666', marginRight: '4px' }}
        >
          If
        </Typography>
      </Box>
        </div>

        <div style={{ display:'flex',backgroundColor:'#F5F5FF',borderRadius:'10px',minHeight:'25px'}}>
      <Box display="flex" alignItems="center" flex="1" >
        <BoltIcon fontSize="small" sx={{ color: '#f0ad4e', marginRight: '4px' }} />
        <Typography
          variant="body2"
          sx={{
            fontSize: '0.875rem',
            fontWeight: 500,
            color: '#333',
          }}
        >
            {executionPlan && executionPlan.condition && executionPlan.condition.length > 0 ? (
    executionPlan.condition.map((item, index) => (
      <div key={index}>
        <div className="rule__engine__if__then__else__node">
          {index === 0 ? (
            <span>{'if('}</span>
          ) : (
            <span>{item.operator && executionPlan.condition && executionPlan.condition[index-1].operator.toLowerCase()+'('}</span>
          )}
          <div>
            {'"'}
            <span className="rule__engine__define__execution__selected__parameters">
              {item.parameterName && item.parameterName}
            </span>
            {'" '}
          </div>
          &nbsp;<span>{item.parameterConditionName && item.parameterConditionName}</span>&nbsp;
          {(item.parameterConditionName === 'Between' || item.parameterConditionName === 'Not between')  ?  <span className='rule__engine__define__execution__selected__parameters'>{item.parameterValue && item.parameterValue[0]}</span>: <span className='rule__engine__define__execution__selected__parameters'>{item.parameterValue && item.parameterValue.join(',')}</span>}
          <span className="rule__engine__define__execution__selected__parameters">
            {item.unit && item.unit.length > 0 ? item.unit : ''}
          </span>
          &nbsp;<span className='rule__engine__define__execution__selected__parameters'>{item.parameterConditionName === 'Between' || item.parameterConditionName === 'Not between' ? 'to' : null }</span>&nbsp;
          <span className="rule__engine__define__execution__selected__parameters">{item.parameterValue && item.parameterValue.length > 1 && item.parameterValue[1]}</span>
          <span className="rule__engine__define__execution__selected__parameters">
            {item.parameterValue && item.parameterValue.length > 1 &&
              item.parameterValue[1] &&
              item.unit &&
              item.unit.length > 0
              ? item.unit + ' '
              : ''}
          </span>
          <span>{')'}</span>
        </div>
      </div>
    ))
  ) : (
    // Fallback content when there's no executionPlan or condition is empty
    <div>
      ((Parameter),(Operator),(Value))
    </div>
  )}
               
        </Typography>
      </Box>

      <IconButton
        size="small"
        onClick={handleEditClick}
        sx={{
          ':hover': {
            backgroundColor: 'transparent', // Removes background hover effect
          },
        }}
  
      >
        <EditIcon fontSize="small" sx={{ color: '#666' }} />
            </IconButton>
            

    {/* Close ICON */}
      <IconButton
        size="small"
        onClick={()=>{}}
        sx={{
          position: 'absolute',
          right: '8px',
          top: '8px',
        }}
      >
          <CloseIcon
            onClick={()=> updateEdgesAndNodes()}
            fontSize="small"
            sx={{ color: '#666' }} />
      </IconButton>
        </div>
      </div>

    </Paper>
    {isIfElseSidePopOverOpen && <SidePopover
         id='rule__engine__execution__plan__if__then__else__side__popOver'
         open={isIfElseSidePopOverOpen}
         headerText={'Flow Control: If-Then-Else'}
         headerSecondaryText={'Condition'}
         containerClassName='configure__notification__detail__popover'
         handleClose={()=>{}}
         popperClassName='rule__engine__if__then__else'
         headerClassName={'rule__engine__header__config'}
         headingIconClassName={'execution_plan_button_define'}
         horizontalLinePopover={true}
         isRuleEnginePopOver={true}
         infoTitle={'Statements are executed in the order precedence of AND and then OR logic'}
         ruleEngineSidePopoverOnclose={handleCloseIfElsePopover}
         // eslint-disable-next-line react/no-children-prop
         children={
              <IfThenElseSidePopOver 
                  handleClose={handleCloseIfElsePopover} 
                  executionPlan={executionPlan}
                  handleUpdateExecutionPlan={handleUpdateExecutionPlan}
              />
        } 
         />
       }
    </>
   
  );

};

export default (CustomNode);
