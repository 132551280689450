import React, { FC, useEffect, useMemo } from 'react'
import { fetchAlarmSeverityReferenceData, fetchChargerLocationReferenceData, fetchChargerModelReferenceData, fetchChargerStationReferenceData, fetchChargerStatusReferenceData,fetchChargerProductTypeReferenceData ,fetchChargerTypeReferenceData, fetchCustomerReferenceData, fetchRegionReferenceData, fetchSoftwarVersionReferenceData,fetchAlarmTypeReferenceData,fetchChargerVisibleIdReferenceData,getReferenceDataReducer, getFilterSelectedValue, getFilterReducer,fetchUsersReferenceData,fetchParametersReferenceData, fetchOrgReferenceData, fetchChargerVisibilityReferenceData,fetchExceptionLogsSeverityReferenceData, fetchAlarmRules, fetchAlarmTypeOfNotification, fetchConfigureNotificationHistoryRuleIdReferenceData, fetchChargerRuleEngineTriggerCategoryReferenceData, fetchChargerRuleEngineActionControlReferenceData, fetchChargerRuleEngineScheduleReferenceData, fetchChargerRuleEngineTriggerLevelReferenceData, fetchChargerRuleEngineStatusReferenceData, fetchChargerRuleEngineRuleNameReferenceData} from '../../rmsReduxStore/reduxExports'
import SelectDropDown, { CustomDropdownPropTypes } from '../SelectDropDown/SelectDropDown'
import { useAppDispatch, useAppSelector } from '../globalExports'
import CustomDatePicker, { CustomDatePickerProps } from '../CustomDatePicker/CustomDatePicker'
import { fetchAlarmRuleSeverityReferenceData, fetchChargerActiveCertificateReferenceData } from '../../rmsReduxStore/referenceDataRedux/referenceDataCreators'
// import { cloneDeep } from 'lodash'

export interface FilterProp {
  filterId: string
  filterLabel: string
  filterType: 'dropdown' | 'dateFilter'
  filterDropdownProps?: CustomDropdownPropTypes | null,
  dateFilterProps?: CustomDatePickerProps | null
  width?: string
}

interface FilterLayoutProps {
  screen?:string
  filters: FilterProp[],
  excludeGlobalFilters?: boolean,
  requiredReferenceDataCallbacks: ('customer' | 'severity' | 'chargerModel' | 'location' | 'status' | 'chargerType' | 'region' | 'softwareVersion' | 'chargingStation' | 'alarmType' | 'chargerVisibleId' | 'parameterName' | 'user' | 'editHistory' | 'orgName' | 'visibility' | 'chargingStationId'|'productType'|'alarmTypeId'|'typeOfNotification'| 'ruleId' | 'alarmSeverity' |'activeCertificate' |'userRemoteCommand')[] | string[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleFilterDataChange?:Function;
}

export const referenceDataCallBackMapper = [
  {
    key: 'customer',
    referenceDataCallBack: fetchCustomerReferenceData
  },
  {
    key: 'severity',
    referenceDataCallBack: fetchAlarmSeverityReferenceData
  },
  {
    key: 'chargerModel',
    referenceDataCallBack: fetchChargerModelReferenceData
  },
  {
    key: 'location',
    referenceDataCallBack: fetchChargerLocationReferenceData
  },
  {
    key: 'status',
    referenceDataCallBack: fetchChargerStatusReferenceData
  },
  {
    key: 'productType',
    referenceDataCallBack: fetchChargerProductTypeReferenceData
  },
  {
    key: 'activeCertificate',
    referenceDataCallBack: fetchChargerActiveCertificateReferenceData
  },
  {
    key: 'chargerType',
    referenceDataCallBack: fetchChargerTypeReferenceData
  },
  {
    key: 'region',
    referenceDataCallBack: fetchRegionReferenceData
  },
  {
    key: 'softwareVersion',
    referenceDataCallBack: fetchSoftwarVersionReferenceData
  },
  {
    key: 'chargingStation',
    referenceDataCallBack: fetchChargerStationReferenceData
  },
  {
    key: 'alarmType',
    referenceDataCallBack: fetchAlarmTypeReferenceData
  },
  {
    key : 'chargerVisibleId',
    referenceDataCallBack: fetchChargerVisibleIdReferenceData
  },
  {
    key : 'parameterName',
    referenceDataCallBack: fetchParametersReferenceData
  },
  {
    key : 'user',
    referenceDataCallBack: fetchUsersReferenceData
  },
  {
    key : 'orgName',
    referenceDataCallBack: fetchOrgReferenceData
  },
  {
     key:'visibility',
     referenceDataCallBack:fetchChargerVisibilityReferenceData
  },
  {
      key:'chargingStationId',
      referenceDataCallBack:fetchChargerStationReferenceData
  },
  {
      key:'logSeverity',
      referenceDataCallBack:fetchExceptionLogsSeverityReferenceData
 },
 {
      key:'alarmTypeId',
      referenceDataCallBack:fetchAlarmRules 
 },
 {
      key:'typeOfNotification',
      referenceDataCallBack:fetchAlarmTypeOfNotification
 },
 {
      key:'ruleId',
      referenceDataCallBack: fetchConfigureNotificationHistoryRuleIdReferenceData
 },

  {
    key: 'triggerCategory',
    referenceDataCallBack: fetchChargerRuleEngineTriggerCategoryReferenceData
  },
  {
    key: 'triggerLevel',
    referenceDataCallBack: fetchChargerRuleEngineTriggerLevelReferenceData
  },
  {
    key: 'actionControls',
    referenceDataCallBack: fetchChargerRuleEngineActionControlReferenceData
  },
  {
    key: 'schedule',
    referenceDataCallBack: fetchChargerRuleEngineScheduleReferenceData
  },
  {
    key:'ruleEngineChipStatus',
    referenceDataCallBack: fetchChargerRuleEngineStatusReferenceData
  },
  {
    key:'ruleEngineRuleName',
    referenceDataCallBack:fetchChargerRuleEngineRuleNameReferenceData
  },
  {
    key: 'alarmSeverity',
    referenceDataCallBack: fetchAlarmRuleSeverityReferenceData // change the function 
  } ,
  {
    key:'logSeverity',
    referenceDataCallBack:fetchExceptionLogsSeverityReferenceData
  },
 {
  key:'userRemoteCommand',
  referenceDataCallBack:fetchUsersReferenceData
 }
]

const FilterLayout: FC<FilterLayoutProps> = (props) => {
  const {screen, filters, requiredReferenceDataCallbacks,handleFilterDataChange, excludeGlobalFilters } = props;
  const dispatch = useAppDispatch()
  const {customerReferenceData } = useAppSelector(getReferenceDataReducer);
  const {globalFilters} = useAppSelector(getFilterReducer)

  useEffect(() => {
    if (requiredReferenceDataCallbacks && requiredReferenceDataCallbacks?.length > 0) {
      // Adding Global filters callBacks key to coming callBacks keys props...
      const referenceDataCallBacks = requiredReferenceDataCallbacks.concat(['customer'])
      referenceDataCallBacks?.forEach(type => {
        const callbackItem = referenceDataCallBackMapper?.find(item => item?.key === type);
        if (callbackItem) {
          if ((screen === 'charging_history' || screen === 'alarms') && type === 'chargerVisibleId') {
            const selectedCustomerFilter = getFilterSelectedValue('customer','global',true)
            const reqBody = {
              customerIds : selectedCustomerFilter?.filter((item) => item?.id === 'all')?.length > 0  ? ['all'] : selectedCustomerFilter?.map((customer) => customer?.id)
            }
             dispatch(callbackItem?.referenceDataCallBack(reqBody))
          } else {
            dispatch(callbackItem?.referenceDataCallBack())
          }
        }
      })
    } else {
      dispatch(fetchCustomerReferenceData())
    }
  }, [])


  const globalFiltersProps: FilterProp[] = useMemo(() => {
    return [
      {
        filterLabel: 'Customers',
        filterType: 'dropdown',
        filterId: 'Customer',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('customer','global',true),
          selectOptions: customerReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange?.(val, 'customer',true)
          },
          selectDropDownId: 'charger-customer-filter-dropdown',
          isMultiSelect: true
        }
      },
    ]
    
  }, [ customerReferenceData,globalFilters])



  const allFilters = excludeGlobalFilters? [...filters] : [...globalFiltersProps,...filters]

  return (
    allFilters && allFilters?.length > 0 && allFilters?.map((filter, index) => {
      return (
        <div key={index} className="side__filter__wrap-content-row" style={{width: filter?.width}}>
          <div className="side__filter__wrap-content-row-label">{filter?.filterLabel}</div>
          <div className="side__filter__wrap-content_filter">
            {filter.filterType === 'dropdown' && filter?.filterDropdownProps &&
              <SelectDropDown
                selectValue={filter?.filterDropdownProps?.selectValue}
                selectOptions={filter?.filterDropdownProps?.selectOptions ?? []}
                handleSelectOptionChange={filter?.filterDropdownProps?.handleSelectOptionChange}
                selectDropDownId={filter?.filterDropdownProps?.selectDropDownId}
                isMultiSelect={filter?.filterDropdownProps?.isMultiSelect}
                // renderToBody={true}
              />
            }
            {filter.filterType === 'dateFilter' && filter?.dateFilterProps &&
              <CustomDatePicker startDate={filter?.dateFilterProps?.startDate ? new Date(filter?.dateFilterProps?.startDate) : null} endDate={filter?.dateFilterProps?.endDate ? new Date(filter?.dateFilterProps?.endDate) : null} selectsRange={filter?.dateFilterProps?.selectsRange} onChange={filter?.dateFilterProps?.onChange} includeDateIntervals={filter?.dateFilterProps.includeDateIntervals} />}
          </div>
        </div>
      )
    }
    ))
}

export default FilterLayout