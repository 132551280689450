/* eslint-disable react/no-unescaped-entities */
import React, {  FC } from 'react';
import { Tooltip, IconButton, FormLabel } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomInput, SelectDropDown } from '../../globalUtils/globalExports';
import { CustomDropdownPropTypes } from '../../globalUtils/SelectDropDown/SelectDropDown';
import { CustomInputFieldPropTypes } from '../../globalUtils/CustomInput/CustomInput';
import './CreateRuleAlertLogic.css'

interface IAlarmList {
  severity: string,
  id: string,
  name: string
}

export interface customProps {
  alarmList?: IAlarmList[] | null;
  selectDropdownProps: CustomDropdownPropTypes | null; 
  formLabel: string;
  isRequired: boolean;

  inputProps: CustomInputFieldPropTypes | null;
}

const CreateRuleAlertLogic: FC<customProps> = (props) => {
  // const [expanded, setExpanded] = useState<boolean>(true);

  // const handleAccordionToggle = () => {
  // if (
  //   (Array.isArray(props?.selectDropdownProps?.selectValue) &&
  //     (!props?.selectDropdownProps?.selectValue.length || !props?.selectDropdownProps?.selectValue[0]?.name)) || 
  //   (!Array.isArray(props?.selectDropdownProps?.selectValue) &&
  //     (!props?.selectDropdownProps?.selectValue?.name))
  // ) {
  //   toast.error('Select an alarm first.', {
  //     position: 'bottom-left',
  //   });
  //   return;
  // }
    // if (!props?.selectDropdownProps?.isDisabled) {
    //   toast.error('Select an alarm first.', {
    //     position: 'bottom-left',
    //   });
    //   return;
    // }
    // setExpanded(!expanded);
  // };

  return (
    <>
    <div className='create__rule__alert__logic__wrapper'>
      <div className='create__rule__alert__logic__header__info__wrapper'>
        <div className='create_rule_alert_section_headings'>
          Alert Logic
        </div>
        <Tooltip
            title={
              <>
                Alert logic requires the 'Alarm' field to be mandatory, while the 'Alarm continues for' field is optional.
                <br />
                - To receive a notification whenever a new alarm is triggered, select the 'Alarm' option.
                <br />
                - Additionally, a time-based logic can be specified in the 'Alarm continues for' field (up to 4 hours i.e., 240 minutes) to receive a notification when the alarm continues to exist for that duration.
              </>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  boxShadow: 'none', 
    
                },
              },
            }}
          >
      <IconButton sx={{ padding: 0, marginLeft: 1 }}>
        <InfoOutlinedIcon fontSize="small" />
      </IconButton>
        </Tooltip>
      </div>
      <div className={'alert__logic__row__content'}>
        <div className='custom__form__wrap-content-column alert__logic__alarm__dropdown'>
          <div className='flex-tooltip'>
            {props?.formLabel && (
              <FormLabel className={'rms-form-label'} required={props?.isRequired}>
                {props?.formLabel}
              </FormLabel>
            )}
          </div>
          
          {props?.selectDropdownProps !== null && (
            <SelectDropDown 
              selectValue={props?.selectDropdownProps?.selectValue}
              selectOptions={props?.selectDropdownProps?.selectOptions}
              handleSelectOptionChange={props?.selectDropdownProps?.handleSelectOptionChange}
              selectDropDownId={props?.selectDropdownProps?.selectDropDownId}
              selectDropDownName={props?.selectDropdownProps?.selectDropDownName}
              isMultiSelect={props?.selectDropdownProps?.isMultiSelect}
              isDisabled={props?.selectDropdownProps?.isDisabled}
              handleClose={props?.selectDropdownProps?.handleClose}
              renderToBody={props?.selectDropdownProps?.renderToBody}
              secondaryLabel={props?.selectDropdownProps?.secondaryLabel}
              secondaryLabelView={props?.selectDropdownProps?.secondaryLabelView}
            />
          )}
          
        </div>
        <div className='alert__logic__alarm__continues__for'>
            <div className='alert__logic__row__label'>
              Alarm Conitnues for
            </div>
            <div className='alert__logic__minute__input'>
            <CustomInput
                    inputPlaceHolder={''}
                    handleInputChange={props?.inputProps?.handleInputChange}
                    inputValue={props?.inputProps?.inputValue || ''}
                    inputFieldId={props?.inputProps?.inputFieldId || ''}
                    isRequired={props?.inputProps?.isRequired || false}
                    handleKeyPress={(e) => {
                      if (!/^\d$/.test(e.key) && e.key !== 'Backspace') {
                        e.preventDefault(); 
                      }
                    }}
                    isDisabled={props?.inputProps?.isDisabled}
                    errorMessage={props?.inputProps?.errorMessage}
                    autoFocus={props?.inputProps?.autoFocus}
                  /> minutes
            </div>
            
        </div>
        {/* <Accordion
            expanded={expanded}
            onChange={handleAccordionToggle}
            sx={{ mt: 5, background:'#FAFAFA'}} 
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ padding: '4px 8px', margin: 0 }} 
            >
              <Typography variant="body2" sx={{ fontSize: '12px', margin: 0 }}>
                Advanced option
              </Typography>
              <Tooltip title="If the Alarm continues for a specific time, then send notification">
                <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: '4px 8px', margin: 0, maxHeight: '150px', overflow: 'auto' ,mb:'10px'}}>
              {/* Alarm continues for input and dropdowns */}
              {/* <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: '0.75rem', whiteSpace: 'nowrap'}}
                >
                  Alarm continues for
                </Typography>

                <div className={'custom__form__wrap-content-row ' + 'grid-2-custom'}>
                  <CustomInput
                    inputPlaceHolder={''}
                    handleInputChange={props?.inputProps?.handleInputChange}
                    inputValue={props?.inputProps?.inputValue || ''}
                    inputFieldId={props?.inputProps?.inputFieldId || ''}
                    isRequired={props?.inputProps?.isRequired || false}
                    handleKeyPress={(e) => {
                      // Allow only digits and the Backspace key
                      if (!/^\d$/.test(e.key) && e.key !== 'Backspace') {
                        e.preventDefault(); // Prevent non-numeric characters except Backspace
                      }
                    }}
                    isDisabled={props?.inputProps?.isDisabled}
                    errorMessage={props?.inputProps?.errorMessage}
                    autoFocus={props?.inputProps?.autoFocus}
                  />
                </div>

                <Typography
                  variant="body2"
                  sx={{ fontSize: '0.75rem', whiteSpace: 'nowrap'}}
                >
                  minutes.
                </Typography>
              </Box>
            </AccordionDetails> */}
          {/* </Accordion> */} 
      </div>
    </div>
    </>
  );
};

export default CreateRuleAlertLogic;