/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/ban-types */
import React, { FC, forwardRef, useEffect, useRef, useState } from 'react';
import './IconDatePicker.css'
import { Icon, IconWithTooltip } from '../globalIcons';
import { formatDateWithYear } from '../globalHooks';
import EventIcon from '@mui/icons-material/Event';
import { CustomDatePicker } from '../globalExports';

export interface IconDatePickerProps {
  startDate: Date | null,
  endDate?: Date | null,
  minDate?: Date | null
  maxDate?: Date | null
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange?: Function,
  showTimePicker?: boolean
  timeInterval?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  datePickerId?: string
  datePickerClassName?: string
  customInputPlaceHolder?: string
  showIcon?: boolean
  disabled?: boolean
  popperPlacement?: string
  selectsRange?: boolean
  hideText?: boolean
  renderToBody?: boolean
}

const IconDatePicker: FC<IconDatePickerProps> = (props) => {
  const { datePickerId, startDate, renderToBody, disabled, onChange, popperPlacement, showTimePicker, timeInterval, datePickerClassName, showIcon, selectsRange, endDate, hideText } = props
  const inputRef = useRef(null);
  const [stDate, setStDate] = useState<Date | null>(startDate)

  useEffect(() => {
    if (showTimePicker && !selectsRange) {
      setStDate(startDate)
    }
  }, [startDate])

  const displayValue = () => {
    if (startDate && endDate) {
      return `${formatDateWithYear(new Date(startDate))} - ${formatDateWithYear(new Date(endDate))}`
    }
    else if (startDate) {
      return formatDateWithYear(new Date(startDate))
    }
    return '';
  }


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomInput = forwardRef((props: any, ref: any) => (
    <div className={'rms__icon__date__picker__wrap ' + datePickerClassName} ref={ref}>
      <>
        {props.value ?
          <div className='rms__icon__date__picker__value' ref={ref} onClick={(e) => { e?.stopPropagation(); props.onClick(); }}>
            {showIcon && <EventIcon />}
            {!hideText && <div>{displayValue()} </div>}
          </div> :
          <div className='rms__icon__date__picker__placeholder' ref={ref} onClick={(e) => { e?.stopPropagation(); props.onClick?.() }}>
            <EventIcon />
          </div>
        }
      </>
    </div>
  ));

  CustomInput.displayName = 'DatePickerCustomInput'

  return (

    <CustomDatePicker
      datePickerClassName={'icon__date__picker ' + datePickerClassName}
      datePickerId={datePickerId}
      selectsRange={selectsRange ?? false}
      showTimePicker={showTimePicker}
      timeInterval={timeInterval} startDate={showTimePicker && !selectsRange ? stDate : startDate} endDate={endDate ? endDate : null}
      onChange={(selected) => showTimePicker && !selectsRange ? setStDate(selected) : onChange?.(selected)}
      customInput={<CustomInput inputRef={inputRef} {...props} />}
      renderToBody={renderToBody}
      disabled={disabled}
      popperPlacement={popperPlacement}
      onSubmit={(selected) => onChange?.(selected)}
    />

  );

};

export default IconDatePicker;
