import { AxisLabelsFormatterContextObject, Point, TooltipFormatterContextObject } from 'highcharts';
import React from 'react';
import './HighchartsTimeline.css';
import { CustomHighCharts } from '../../globalUtils/globalExports';
import { TimeLineDataPoint } from './HistorySidePopoverPage';
import moment from 'moment';

interface TimeLineProps {
  timeLineDataPoints: TimeLineDataPoint[] | [];
}

const HighchartsTimeline = function HighchartsTimeline(props: TimeLineProps) {
  const { timeLineDataPoints } = props;

  const startDate = new Date(timeLineDataPoints[0]?.x);
  const endDate = new Date(timeLineDataPoints[timeLineDataPoints.length - 1]?.x);
  const diffInMs = endDate?.getTime() - startDate?.getTime();
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  let minorTickInterval;
  if (diffInDays < 1) {
    minorTickInterval = 3600 * 1000;
  } else if (diffInDays >= 1 && diffInDays <= 7) {
    minorTickInterval = 6 * 3600 * 1000;
  } else {
    minorTickInterval = 24 * 3600 * 1000;
  }
  let tickInterval;
  if (diffInDays < 1) {
    tickInterval = 4 * 3600 * 1000;
  } else if (diffInDays >= 1 && diffInDays <= 7) {
    tickInterval = 24 * 3600 * 1000;
  } else {
    tickInterval = 5 * 24 * 3600 * 1000;
  }

  const options = {
    title: {
      text: '',
    },
    time: {
      useUTC: false,
      timezone: 'Asia/Calcutta',
    },
    chart: {
      className: 'history__timeline__container',
      type: 'timeline',
    },
    xAxis: {
      type: 'datetime',
      visible: true,
      min: timeLineDataPoints[0]?.x,
      max: timeLineDataPoints[timeLineDataPoints.length - 1]?.x,
      gridLineWidth: 0,
      labels: {
        enabled: true,
        // style: {
        //   fontSize: '10px',
        //   fontFamily: 'var(--font-family-regular)',
        //   color: 'var(--primary-text-color)'
        // },
        useHTML: true,
        // autoRotation: [90],
        // autoRotationLimit: 20,
        formatter: function (this: AxisLabelsFormatterContextObject): string {
          const timestamp = this.value as number;
          const date = new Date(timestamp);
          const formattedDate = `${moment(date).format('MMM Do')}`;
          const formattedTime = ` ${moment(date).format('LT')}`;
          return `${formattedDate} <br/> ${formattedTime}`;
        },
      },
      // startOnTick: true,
      // endOnTick: true,
      tickColor: 'gray',
      lineWidth: 0,
      tickInterval: tickInterval,
      minorTickInterval: minorTickInterval,
      // tickInterval: 6 * 3600 * 1000,
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: '',
      },
      labels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      outside: true,
      style: {
        zIndex: 10
      },
      formatter: function (this: TooltipFormatterContextObject): string {
        const date = this.key ? moment(new Date(this.point.x)).format('llll') : '-';
        return (
          '<div>Notification Count:' +
          this.point.name +
          `</div>
          <br/>
          <div style='font-size:10px;' class='graph__tooltip__header'>Date: ` +
          date +
          '</div>'
        );
      },
    },
    series: [
      {
        zoneAxis: 'x',
        name: 'Timeline',
        // type: 'timeline',
        lineWidth: 6,
        zones: [
          {
            value: timeLineDataPoints?.find((point) => point?.name > 0)?.x,
            color: 'gray',
          },
          {
            color: 'red',
          },
        ],
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 0,
          lineWidth: 0,
        },
        dataLabels: {
          enabled: true,
          distance: 0,
          borderWidth: 0,
          padding: -6,
          useHTML: true,
          color: '#ffffff',
          formatter: function (this: TooltipFormatterContextObject) {
            const pointData = this.point as Point;

            // eslint-disable-next-line quotes
            return parseInt(pointData.name) !== 0
              ? `<span style='background-color: #ffffff; color: #000000; display: flex; justify-content: center; align-items: center; width: 24px; height: 24px; padding: 2px; border-radius: 50%; border: 1px solid black'>${pointData.name}</span>`
              : null;
          },
        },
        data: timeLineDataPoints,
      },
    ],
  };

  return (
    <>
      <div className='configure_notification_history_timeline_container'>
        <CustomHighCharts highChartOptions={options} />
      </div>
    </>
  );
};

export default HighchartsTimeline;
